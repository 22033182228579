

.title1 {
  text-align: left;
    
  font-weight: 600;
  font-size: 23px;
  color: black;
  font-family: "Poppins";
 // width: 580px;
   @media screen and (max-width: 1200px) {
    font-size: 22px;
  }
  @media screen and (max-width: 991px) {
    font-size: 20px;
  }
  @media screen and (max-width: 767px) {
    font-size: 18px;
  }
  @media screen and (max-width: 474px) {
    font-size: 16px;
    width: 350px;
  }
}
.details1 {
 margin-top: 5px;
  text-align: left;
    
  font-weight: 500;
  font-size: 20px;
  color: black;
  font-family: "Poppins";
  
  @media screen and (max-width: 1200px) {
    font-size: 20px;
  }
  @media screen and (max-width: 991px) {
    font-size: 19px;
  }
  @media screen and (max-width: 767px) {
    font-size: 18px;
  }
  @media screen and (max-width: 474px) {
    font-size: 16px;
  }
}
.beforeTitle1{
  position: relative;
  width: 100%;
margin-left:30px;
@media screen and (max-width: 1200px) {
  margin-left:30px;
}
@media screen and (max-width: 991px) {
  margin-left:20px;
}
@media screen and (max-width: 767px) {
  margin-left:10px;

}
@media screen and (max-width: 474px) {
  margin-left:0px;
}

  &:before {
      
      left:-60px;
      top: 0;
      position: absolute;
      content: url(../assets/beforeTitle.svg);
      @media screen and (max-width: 474px) {
        margin-left:0px;
        left:-37px;
        content: url(../assets/prefixb.svg);
      }
    
    }
 
     
}
.RowTitle{
  margin: 30px auto ;
  margin-left:30px;
  margin-bottom:20px;
  margin-top:80px;
  @media screen and (max-width: 474px) {
    margin-left:25px;
  }
}
.mainTitle1 {
  font-family: Agency FB, sans-serif;
  text-align: left;
  

  font-weight: 600;
  font-size: 42px;
  line-height: 15.62px;
  color: black;

  margin-bottom: 15px;
 
  @media screen and (max-width: 1200px) {
    font-size: 40px;
  }
  @media screen and (max-width: 991px) {
    font-size: 38px;
  }
  @media screen and (max-width: 767px) {
    font-size: 36px;
  }
  @media screen and (max-width: 474px) {
    font-size: 28px;
    line-height: 28px;
   
  }
}


.pDetails111{
  

  margin-top: 0px;
  margin-bottom: 0;
  font-weight:300;
  font-size:18px;
  color:black;
  width:450px;
  @media screen and (max-width:1200px){

    font-size:18px;
    width:400px;
  
  }
  @media screen and (max-width:991px){
  
    font-size:18px;
    width:350px;
    
  }
  @media screen and (max-width:767px){
 
    font-size:17px;
   
   
  }
  @media screen and (max-width:474px){
  
    font-size:17px;
   width: auto;


    
  }
}
.plogo{
  width:150px;
  // height: 100px;
  padding-left: 0;
margin-top: 90px;
@media screen and (max-width:474px){
  margin-top: 20px;
}
}
.plogo75{
  width:150px;
  // height: 100px;
  padding-left: 0;
margin-top: 63px;
@media screen and (max-width:474px){
  margin-top: 20px;
}
}
.plogo10{
  height: 86px;
  width: 106px;
  padding-left: 0;
  margin-top: 45px;
  @media screen and (max-width:991px){
    margin-top: 35px;
  }
  @media screen and (max-width:474px){
    margin-top: 20px;
  }
}
.studrow{
margin-left: 58px;

@media screen and (max-width:474px){
  margin-left: -20px;
}
}
.animateL{
    position: relative;

    animation-name: example !important;
    animation-duration: 2s;
    animation-iteration-count: 1;
  
  @media screen and (max-width:474px){
    margin-left: 50px;
    
  }

}

@keyframes example {
    0%   {left:-200px; top:0px;opacity:0;}
   
    100% { left:0px; top:0px;opacity: 1;}
  }
.animateR{
   
    position: relative;
    animation-name: example1 !important;
    animation-duration: 2s;
    animation-iteration-count: 1;
    @media screen and (max-width:474px){
      margin-left: 50px;
      
    }
    @media screen and (max-width:400px){
      margin-left: 30px;
      
    }
  
}

@keyframes example1 {
    0%   {right:-200px; top:0px;opacity: 0;}
   
    100% { right:0px; top:0px;opacity: 1;}

    
  }


  .animateL1{
    position: relative;
 
    animation-name: example0 !important;
    animation-duration: 2s;
    animation-iteration-count: 1;
    @media screen and (max-width:474px){
      margin-left: 50px;
      
    }
}

@keyframes example0 {
    0%   {left:-200px; top:0px;opacity: 0;}
   
    100% { left:0px; top:0px;opacity: 1;}
  }
.animateR1{
   
    position: relative;
    animation-name: example10 !important;
    animation-duration: 2s;
    animation-iteration-count: 1;
    @media screen and (max-width:474px){
      margin-left: 50px;
      
    }
}

@keyframes example10 {
    0%   {right:-200px; top:0px;opacity: 0;}
   
    100% { right:0px; top:0px;opacity: 1;}
  }

  .animateL2{
    position: relative;
 
    animation-name: example2 !important;
    animation-duration: 2s;
    animation-iteration-count: 1;
    @media screen and (max-width:474px){
      margin-left: 50px;
      
    }
}

@keyframes example2{
    0%   {left:-200px; top:0px;opacity: 0;}
   
    100% { left:0px; top:0px;opacity: 1;}
  }
.animateR2{
   
    position: relative;
    animation-name: example20 !important;
    animation-duration: 2s;
    animation-iteration-count: 1;
    @media screen and (max-width:474px){
      margin-left: 50px;
      
    }
}

@keyframes example20 {
    0%   {right:-200px; top:0px;opacity: 0;}
   
    100% { right:0px; top:0px;opacity: 1;}
  }
  .animateL3{
    position: relative;
 
    animation-name: example3 !important;
    animation-duration: 2s;
    animation-iteration-count: 1;
    @media screen and (max-width:474px){
      margin-left: 50px;
      
    }
}

@keyframes example3{
    0%   {left:-200px; top:0px;opacity: 0;}
   
    100% { left:0px; top:0px;opacity: 1;}
  }
.animateR3{
   
    position: relative;
    animation-name: example30 !important;
    animation-duration: 2s;
    animation-iteration-count: 1;
    @media screen and (max-width:474px){
      margin-left: 50px;
      
    }
}

@keyframes example30 {
    0%   {right:-200px; top:0px;opacity: 0;}
   
    100% { right:0px; top:0px;opacity: 1;}
  }

  .animateL4{
    position: relative;
 
    animation-name: example4 !important;
    animation-duration: 2s;
    animation-iteration-count: 1;
    @media screen and (max-width:474px){
      margin-left: 50px;
      
    }
}

@keyframes example4{
    0%   {left:-200px; top:0px;opacity: 0;}
   
    100% { left:0px; top:0px;opacity: 1;}
  }
.animateR4{
   
    position: relative;
    animation-name: example40 !important;
    animation-duration: 2s;
    animation-iteration-count: 1;
    @media screen and (max-width:474px){
      margin-left: 50px;
      
    }
}

@keyframes example40 {
    0%   {right:-200px; top:0px;opacity: 0;}
   
    100% { right:0px; top:0px;opacity: 1;}
  }
  .animateL5{
    position: relative;
 
    animation-name: example5 !important;
    animation-duration: 2s;
    animation-iteration-count: 1;
    @media screen and (max-width:474px){
      margin-left: 50px;
      
    }
}

@keyframes example5{
    0%   {left:-200px; top:0px;opacity: 0;}
   
    100% { left:0px; top:0px;opacity: 1;}
  }
.animateR5{
   
    position: relative;
    animation-name: example50 !important;
    animation-duration: 2s;
    animation-iteration-count: 1;
    @media screen and (max-width:474px){
      margin-left: 50px;
      
    }
}

@keyframes example50 {
    0%   {right:-200px; top:0px;opacity: 0;}
   
    100% { right:0px; top:0px;opacity: 1;}
  }
  .studyImg{
    width: 12px;
  
    margin-right: 20px;
  }
 .pImgs:hover{
    transform: scale(0.98) rotate(3deg);
    transition: all .8s ease-in-out;
   }
   .pImgs:not( :hover ){
    transform: scale(1) rotate(0deg);
    transition: all .8s ease-in-out;
   }
  .pImgs{

   // width:100%;
    height:333px;
    border-radius: 20px;
  //background-size: cover;
 //background-size: contain;
 //background-repeat: no-repeat;
 rotate: -3deg;

  @media screen and (max-width:1200px){
  //  width:100%;
    
    
  }
    @media screen and (max-width:991px){
     // width:100%;
    
      
    }
    @media screen and (max-width:767px){
     width: 100%;
    // height: 455px;
     height: 285px;
      
    }
   
    @media screen and (max-width:474px){
   // margin-top: 20px;
    height: 287px;
    width:100%;
    }
    @media screen and (max-width:400px){
      //margin-top: 20px;
      height: 247px;
      width:100%;
     }
     @media screen and (max-width:364px){
     // margin-top: 20px;
      height: 219px;
      width:100%;
     }
  }
  .pImgs1{

    width:80%;
    height:333px;
  //  background-size: cover;
  background-size: contain;
  background-repeat: no-repeat;
 // border-radius: 20px;
  @media screen and (max-width:1200px){
    width:100%;
    
    
  }
    @media screen and (max-width:991px){
      width:100%;
    
      
    }
    @media screen and (max-width:767px){
      width: 90%;
      height: 285px;
     // height: 455px;
    
      
    }
    @media screen and (max-width:474px){
    margin-top: 20px;
    height: 287px;
    width: 90%;
    }
    @media screen and (max-width:400px){
      margin-top: 20px;
      height: 247px;
      width:95%;
     }
     @media screen and (max-width:364px){
      margin-top: 20px;
      height: 219px;
      width:95%;
     }
  }
  .pImgs2{

    width:80%;
    height:333px;
  //  background-size: cover;
  background-size: contain;
  background-repeat: no-repeat;
   // border-radius:20px;
    @media screen and (max-width:1200px){
      width:100%;
      
      
    }
    @media screen and (max-width:991px){
      width:100%;
    
      
    }
    @media screen and (max-width:767px){
      width: 90%;
     // height: 455px;
     height: 285px;
      
    }
    @media screen and (max-width:474px){
      margin-top: 20px;
      height: 287px;
      width: 90%;
    }
    @media screen and (max-width:400px){
      margin-top: 20px;
      height: 247px;
      width:95%;
     }
     @media screen and (max-width:364px){
      margin-top: 20px;
      height: 219px;
      width:95%;
     }
  }
.plogo90{
  width:85px;
  // height: 100px;
  padding-left: 0;
margin-top: 40px;
@media screen and (max-width:474px){
  margin-top: 20px;
}
}
  
  .pImgs3{

    width:80%;
    height:333px;
  //  background-size: cover;
  background-size: contain;
  background-repeat: no-repeat;
  //  border-radius:20px;
    @media screen and (max-width:1200px){
      width:100%;
      
      
    }
    @media screen and (max-width:991px){
      width:100%;
    
      
    }
    @media screen and (max-width:767px){
      width: 90%;
     // height: 455px;
     height: 285px;
    
      
    }
    @media screen and (max-width:474px){
    margin-top: 20px;
   height: 287px;
    width:90%;
    }
    @media screen and (max-width:400px){
      margin-top: 20px;
      height: 247px;
      width:95%;
     }
     @media screen and (max-width:364px){
      margin-top: 20px;
      height: 219px;
      width:95%;
     }
  }
  .pImgs4{
 
     width:80%;
     height:333px;
   //  background-size: cover;
   background-size: contain;
   background-repeat: no-repeat;
    // border-radius:20px;
     @media screen and (max-width:1200px){
      width:100%;
      
      
    }
     @media screen and (max-width:991px){
       width:100%;
     
       
     }
     @media screen and (max-width:767px){
      width: 90%;
     // height: 455px;
     height: 285px;
    
      
    }
     @media screen and (max-width:474px){
      margin-top: 20px;
      height: 287px;
      width:90%;
     }
     @media screen and (max-width:400px){
      margin-top: 20px;
      height: 247px;
      width:95%;
     }
     @media screen and (max-width:364px){
      margin-top: 20px;
      height: 219px;
      width:95%;
     }
   }

   .pImgs5{

     width:80%;
     height:333px;
   //  background-size: cover;
   background-size: contain;
   background-repeat: no-repeat;
    // border-radius:20px;
     @media screen and (max-width:1200px){
      width:100%;
      
      
    }
     @media screen and (max-width:991px){
       width:100%;
       
       
     }
     @media screen and (max-width:767px){
      width: 90%;
      //height: 455px;
      height: 285px;
    
      
    }
     @media screen and (max-width:474px){
      margin-top: 20px;
      height: 287px;
      width:90%;
     }
     @media screen and (max-width:400px){
      margin-top: 20px;
      height: 247px;
      width:95%;
     }
     @media screen and (max-width:364px){
      margin-top: 20px;
      height: 219px;
      width:95%;
     }
   }
  .projectLink{
    position: absolute;
    color:black;
    text-decoration:underLine;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 17px;
    text-align: left;
    
    top:0;
    right: 113px;
    @media screen and (max-width:474px){
      top:30px;
      right:35%;
      
    
      
    }
  }
.borderImg{
  width: fit-content;
  rotate:3deg;border-radius:20px;
  border:4px solid #7FF593;


}


.title2 {
    text-align: left;
      
    font-weight: 600;
    font-size: 26px;
    color: black;
    font-family: "Poppins";
    width: 580px;
     @media screen and (max-width: 1200px) {
      font-size: 22px;
    }
    @media screen and (max-width: 991px) {
      font-size: 20px;
    }
    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
    @media screen and (max-width: 464px) {
      font-size: 16px;
      width: 350px;
    }
  }
  .details2 {
    text-align: left;
      
    font-weight: 500;
    font-size: 22px;
    color: black;
    font-family: "Poppins";
    text-transform: capitalize;
    @media screen and (max-width: 1200px) {
      font-size: 20px;
      width: 1100px;
    }
    @media screen and (max-width: 991px) {
      font-size: 19px;
      width: 800px;
    }
    @media screen and (max-width: 767px) {
      font-size: 18px;
      width:700px;
    }
    @media screen and (max-width: 464px) {
      font-size: 16px;
      width:500px;
    }
  }
  .beforeTitle2{
    position: relative;
    width: 100%;
  margin-left:30px;
  @media screen and (max-width: 1200px) {
    margin-left:30px;
  }
  @media screen and (max-width: 991px) {
    margin-left:20px;
  }
  @media screen and (max-width: 767px) {
    margin-left:10px;
  
  }
  @media screen and (max-width: 464px) {
    margin-left:0px;
  }
  
    &:before {
        
        left:-60px;
        top: 0;
        position: absolute;
        content: url(../assets/beforeTitle.svg);
      
      }
   
       
  }
  .RowTitle2{
    margin: 30px auto;
    margin-left:30px;
    margin-bottom:20px;
    margin-top:100px;
    @media screen and (max-width: 464px) {
      margin-left:25px;
    }
  }
  .mainTitle2{
    font-family: Agency FB, sans-serif;
    text-align: left;
    
  
    font-weight: 600;
    font-size: 42px;
    line-height: 15.62px;
    color: black;
  
    margin-bottom: 15px;
   
    @media screen and (max-width: 1200px) {
      font-size: 40px;
    }
    @media screen and (max-width: 991px) {
      font-size: 38px;
    }
    @media screen and (max-width: 767px) {
      font-size: 36px;
    }
    @media screen and (max-width: 464px) {
      font-size: 32px;
     
    }
  }
  
  
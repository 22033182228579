.companyBefore{
  position: relative;
  width: 90%;
  margin-left: 77px;
  @media screen and (max-width:1200px){
    width:85%;
    margin-left: 92px;
  
  }
  @media screen and (max-width:991px){
    width:90%;
    margin-left: 72px;
  }
  @media screen and (max-width:767px){
    margin-left:80px;
  }
  @media screen and (max-width:474px){
    width:138%;
    margin-left: 75px;
  
  }
  &:before {


    left:-85px;
    top:0px;
    position: absolute;
    content: url(../../assets/companyLaptop.svg);
 
    @media screen and (max-width:991px){
      content: url(../../assets/companytab.svg);
    }
    @media screen and (max-width:767px){
      content: url(../../assets/companytab.svg);
    }
    @media screen and (max-width:474px){
      content: url(../../assets/companyMobile.svg);
    
    }
    
  }

}
.companys{
  width: 90%;
height:600px;
  position: relative;
 
  // background-image: url(../../assets/company.svg);
  background-image: url(../../assets/companyL.svg);
background-repeat: no-repeat;
 

  @media screen and (max-width:991px){
    //  background-image: url(../../assets/companyT.svg);
    background-image: url(../../assets/companyT1.svg);
  }
  @media screen and (max-width:767px){
    //  background-image: url(../../assets/companyT.svg);
    background-image: url(../../assets/companyT1.svg);
     height:580px;
  }
  @media screen and (max-width:474px){
    //  background-image: url(../../assets/companyM.svg);
    background-image: url(../../assets/companyM1.svg);
     height:400px;
     margin-left: -20px;
  
  }
  
}

.borderdRed{
  position: relative;
  padding-top:0px;
  &:before {
    background-color: white;
    left:-40px;
    top:10px;
    position: absolute;
    content: url(../../assets/rCircle.svg);
    @media screen and (max-width:991px){
      left:-20px;
      top:10px;
    }
    @media screen and (max-width:767px){
      left:30px;
      top:10px;
    }
    @media screen and (max-width:474px){
      left:30px;
      top:0px;
    }
  }
  @media screen and (max-width:991px){
padding-left: 20px;
margin-top: -10px;
  }
  @media screen and (max-width:767px){
    margin-top: 25px;
    padding-left: 65px;
  }
  @media screen and (max-width:474px){
    padding-top:0px;
    margin:0px;
  
  
  }
}
.companyRow{
  margin-top:20px;
  margin-bottom: 0px;
  //margin-left:85px;
  @media screen and (max-width:474px){
   //margin-left: 100px;
   margin-top:0px;
  
  }
}
.borderd{
  position: relative;
  margin-bottom: 20px;
  &:before {

    background-color: white;
    left:-40px;
    top:10px;
    position: absolute;
    content: url(../../assets/aboutLineGroup.svg);
  
    @media screen and (max-width:991px){
      left:-20px;
   
      top:10px;
    }
    @media screen and (max-width:767px){
      left:30px;
      top:10px;
    }
    @media screen and (max-width:474px){
      left:30px;
      top:5px;
    
    }
    
  }

  @media screen and (max-width:991px){
    margin-bottom: 0px;
    padding-left: 20px;
  }
  @media screen and (max-width:767px){
    margin-bottom: 25px;
    padding-left: 65px;
  }
  @media screen and (max-width:474px){
    margin-bottom: 10px;
  
  }

}
.titleabout1{
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: 0em;

  color:black;
  @media screen and (max-width:1200px){
    font-size: 17px;
    font-weight: 580;
    line-height: 40px;
 
  }
  @media screen and (max-width:991px){
    font-size: 16px;
    font-weight: 560;
    line-height: 38px;
  }
  @media screen and (max-width:767px){
    font-size: 15px;
    font-weight: 540;
    line-height: 45px;
  }
  @media screen and (max-width:474px){
    font-size: 14px;
    font-weight: 520;
    line-height: 10px;
  
  }
  
}
.titleabout{
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: 0em;
 width: 300px;
  color:black;
  @media screen and (max-width:1200px){
    font-size: 17px;
    font-weight: 580;
    line-height: 40px;
 
  }
  @media screen and (max-width:991px){
    font-size: 16px;
    font-weight: 560;
    line-height: 38px;
  }
  @media screen and (max-width:767px){
    font-size: 15px;
    font-weight: 540;
    line-height: 45px;
  }
  @media screen and (max-width:474px){
    font-size: 14px;
    font-weight: 520;
    line-height: 33px;
  
  }
  
}

.contentabout{
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
 
  margin-top:-10px;
  color: black;
  @media screen and (max-width:1200px){
    font-size: 14px;
    font-weight: 400;
    line-height: 23px;
 
  }
  @media screen and (max-width:991px){
    font-size: 13px;
    font-weight: 400;
    line-height: 23px;
    margin-top:-20px;
  }
  @media screen and (max-width:767px){
    font-size: 12.5px;
    font-weight: 380;
    line-height: 21px;
  }
  @media screen and (max-width:474px){
    font-size: 12px;
    font-weight: 360;
    line-height: 19px;
    width:300px
  
  }
  
}
.arrowImg{
    animation-name: learn;
    animation-duration: 4s;
    background-color: white;
}
@keyframes learn {
   0% {opacity: 1;}
    25% {opacity: 0.75;}
    50% {opacity: 0.50;}
    75% {opacity: 0.25;}
    100% {opacity: 1;}
  
}
.arrowImg1{
  background-color: white;
    animation-name: learn1;
    animation-duration: 4s;
}
@keyframes learn1 {
   0% {opacity: 0;}
    25% {opacity: 0.25;}
    50% {opacity: 0.50;}
    75% {opacity: 0.75;}
    100% {opacity: 1;}
  
}

.parent {
    position: relative;
    top: 0;
    left: 40px;
    @media screen and (max-width:1200px){
      
      height: 300px;
      
    
    }
    @media screen and (max-width:991px){
      height: 300px;
    }
    @media screen and (max-width:767px){
      height: 300px;
    }
    @media screen and (max-width:474px){
      
      height: 300px;
     
  
    }
  }
  .image1 {
    position: relative;
    top: 0;
    left: 0;
    
  }
  .image2 {
    position: absolute;
    top:-100px;
    left:0;
    @media screen and (max-width:1200px){
      
      position: relative;
      top:-90px;
     left: -150px;
      
    
    }
    @media screen and (max-width:991px){
      position: relative;
       top:-50px;
      left: -150px;
    }
    @media screen and (max-width:767px){
      position: relative;
       top:-40px;
      left: -150px;
    }
    @media screen and (max-width:474px){
      position: relative;
       top:-20px;
    left: -80px;

  
    }
  }
  .image21{
    @media screen and (max-width:474px){
    width: 400px;
    height: 355px;

  
    }
  }
    .bcgteam {
      position: relative;
      top: 0px;
      left: 0;
      width: 230px;
      height: 210px;
      @media screen and (max-width:474px){
       
        top: 30px;
      
        width: 200px;
        height: 180px;
        left:0px;
    
      }
    }
    .imgteam {
      position: absolute;
      top: -42px;
      left:0px;
      width: 230px;
      height: 250px;
      border-end-end-radius: 55%;
      border-end-start-radius: 40%;
      object-fit: inherit;
      @media screen and (max-width:991px){
       
        
    
      }
      @media screen and (max-width:474px){
       
        top: 0px;
      
        width: 200px;
        height: 210px;
        left: 0px;
    
      }
  }

  .divP4{
    position: relative;
    text-align: center;
    margin:0 auto;
    margin-bottom:30px;
    padding-bottom:0px;
    height:fit-content
  
    ;
    @media screen and (max-width:1200px){
      margin-right:30px;


  }
    @media screen and (max-width:991px){
      margin-left:0px;


  }
    @media screen and (max-width:767px){
      margin-left: 0px;


  }
    @media screen and (max-width:474px){
        margin-left: 50px;

     
  
    }}
    .ctdiv{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
   //position: absolute;
   text-align: center;
    //top: 110%;
    //left:30px;
    margin-top: 50px;
    @media screen and (max-width:474px){
     // top: 130%;
     // left:34px;

   

  }
  @media screen and (max-width:397px){
   // top: 130%;
   // left:25px;

 

}
     
    }
  .cardTitle{
    width: 254px;
    line-height:10px;
    font-family:Montserrat;
    font-size:24px;
    font-weight:600;
    @media screen and (max-width:474px){
      font-size:17px;
      font-weight:500;

     
  
    }
  }
  .cardText{
    width: 277px;
 line-height:5px;
  font-family:Montserrat;
  font-size:18px;
  font-weight:500;
  @media screen and (max-width:474px){
    font-size:14px;
    font-weight:400;

   

  }
  }
  .teamDiv{
    position: relative;

top:0;
left:0;
@media screen and (max-width:397px){
  //left:-33px;
}

  }
  .TteamDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top:30px;
    margin-bottom:80px;
    //margin-left:50px;
    @media screen and (max-width:474px){
      margin-left:0px;
      margin-bottom:0px;
    }
    &:hover{
      .bcgteam path {
        fill:url(#paint0_linear_2005_12526);
    
        
      }
    }
  }
  .Carousel {
    display: flex;
    /* width: 840px; */
    flex-wrap: nowrap;
    overflow: auto;
  }

 .whoWeAre{
  margin-top:13px;
  @media screen and (max-width:474px){
    margin-top:-5px;
    margin-left: -37px;
  
  }

 }
  .linkContact{
    color:black;
    text-decoration:underLine;
    font-size:15px;
    font-weight:400;
    padding-left:10px;
    @media screen and (max-width:474px){
      font-size:14px;
      padding-left:0px;
    }
  }
  
  
     
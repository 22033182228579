
  .beforeTitle{
    position: relative;
    width: fit-content;
    margin: auto;
    justify-content: center;
    margin-bottom:-5px;
  //   @media screen and (max-width: 1200px) {
  //     width: 50%;
  //    }
  //  @media screen and (max-width: 991px) {
  //   width: 60%;
  //    }
  //  @media screen and (max-width: 767px) {
  //      width: 80%;
  //    }
   @media screen and (max-width: 474px) {
       width: 100%;
     }
    &:before {
        
        left:0px;
        top: 0;
        position: absolute;
      
       content: url(../assets/wbeforeTitle.svg);
    
      @media screen and (max-width: 474px) {
          content: url(../assets/prefix.svg);
          left:25px;
      }
       
       
      }
   
}

  .title {
    text-align: left;
    margin-left: 63px !important;
    font-weight: 600;
    font-size: 20px;
    color: white;
    font-family: "Poppins";
     @media screen and (max-width: 1200px) {
      font-size: 20px;
     
      font-weight: 550;
    }
    @media screen and (max-width: 991px) {
      font-size: 18px;
    
      font-weight: 500;
    }
    @media screen and (max-width: 767px) {
      font-size: 14px;
    
      font-weight: 450;
    }
    @media screen and (max-width: 474px) {
      font-size: 14px;
   
      font-weight: 400;
    }
  }
  .details {
    font-weight: 350;
    font-size: 18px;
    color: white;
    font-family: "Poppins";
    text-transform: capitalize;
    @media screen and (max-width: 1200px) {
      font-size: 17px;
    }
    @media screen and (max-width: 991px) {
      font-size: 16px;
    }
    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
    @media screen and (max-width: 474px) {
      font-size: 14px;
    }
  }
  .mainTitle {

    text-align: left;
    margin-left: 63px !important;
    font-family: Agency FB, sans-serif;
    font-weight: 600;
    font-size: 45px;
    line-height: 15.62px;
    color: white;
    margin-bottom: 15px;
    @media screen and (max-width: 1200px) {
      font-size: 42px;
    }
    @media screen and (max-width: 991px) {
      font-size: 40px;
    }
    @media screen and (max-width: 767px) {
      font-size: 38px;
    }
    @media screen and (max-width: 474px) {
      font-size: 28px;
    }
  }

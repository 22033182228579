@import "./mixin.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
.navLink{
  z-index:1;
display: block;
  position: absolute;
  top: 35px;
  left: 200px;


  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 45px;

  
letter-spacing: 0em;
text-align: left;
@media screen and (max-width: 991px) {
  display:none;
  
  }
@media screen and (max-width: 767px) {
display:none;

}
@media screen and (max-width: 474px) {
  display:none;
  
  }
.navl1{
  color: white;

  margin-left: 40px;
 
}



}
.modal-body {
  background-image: url(../../../../assets/navbakground.svg);
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Poppins";
  font-weight: 500;
  height: 100vh;

  .logo {
    width: 59px;
    height: 80px;
    margin: 20px 0 0 20px;
    
    @media screen and (max-width: 767px) {
      width: 60px;
      height: 60px;
     
    }
    @media screen and (max-width: 474px) {
    display: none;
    }
  }
  
  .close {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 45px;
    right: 40px;
    cursor: pointer;
    @media screen and (max-width: 767px) {
      width: 40px;
      height: 40px;
    }

    @media screen and (max-width: 474px) {
      width: 40px;
      height: 40px;
    }
  }
 
  .title {
    height: 70px;
    position: relative;
    margin-right: 65px;
    
    a {
      text-align: center;
      color: #fff;
      font-size: 36px;
      
      @media screen and (max-width: 991px) {
        font-size: 28px;
      }
      @media screen and (max-width: 767px) {
        font-size: 24px;
      }
      @media screen and (max-width: 474px) {
        font-size: 22px;
       
      }
    }
.custom-nav-link{

}
  //   .custom-nav-link[aria-label="a1"]::after {
  //     font-size: 25px;
  //     color: #fff;
  //     content: "Creating Superior Digital Experiences";
  //     display: block;
  //     bottom: 0;
  //     visibility: hidden;
  //     @media screen and (max-width: 474px) {
  //       font-size: 20px;
       
  //     }
  //   }

  //   .custom-nav-link[aria-label="a2"]::after {
  //     font-size: 25px;
  //     color: #fff;
  //     content: "Unique Approach To Driving Results Online";
  //     display: block;
  //     bottom: 0;
  //     visibility: hidden;
  //     @media screen and (max-width: 474px) {
  //       font-size: 20px;
       
  //     }
  //   }

  //   .custom-nav-link[aria-label="a3"]::after {
  //     font-size: 25px;
  //     color: #fff;
  //     content: "We Value The Results We Create For Our Clients";
  //     display: block;
  //     bottom: 0;
  //     visibility: hidden;
  //     @media screen and (max-width: 474px) {
  //       font-size: 20px;
       
  //     }
  //   }
  //   .custom-nav-link[aria-label="a4"]::after {
  //     font-size: 25px;
  //     color: #fff;
  //     content: "Proven Industry Leadership & recognized Results";
  //     display: block;
  //     bottom: 0;
  //     visibility: hidden;
  //     @media screen and (max-width: 474px) {
  //       font-size: 20px;
       
  //     }
  //   }
  //   .custom-nav-link[aria-label="a5"]::after {
  //     font-size: 25px;
  //     color: #fff;
  //     content: "Get In Touch With Us";
  //     display: block;
  //     bottom: 0;
  //     visibility: hidden;
  //   }
  }

//   .custom-nav-link:hover {
//     @include keyframes(textgetsmaller) {
//       0% {
//         font-size: 40px;
//       }
//       100% {
//         font-size: 20px;
//       }
//     }
//     @include animate(textgetsmaller, 0.3s, linear, 1, forwards);
//   }
//   .custom-nav-link:hover:after {
//     @include animate(textgetbigger1200, 0.3s, linear, 1, forwards);
//     @media screen and (max-width: 991px) {
//       @include animate(textgetbigger991, 0.3s, linear, 1, forwards);
//     }
//     @media screen and (max-width: 767px) {
//       @include animate(textgetbigger767, 0.3s, linear, 1, forwards);
//     }
//     @media screen and (max-width: 474px) {
//       @include animate(textgetbigger464, 0.3s, linear, 1, forwards);
//     }
//   }
// }

// @include keyframes(textgetbigger1200) {
//   0% {
//     visibility: visible;
//     font-size: 22px;
//   }

//   100% {
//     font-size: 32px;
//     visibility: visible;
//   }
// }

// @include keyframes(textgetbigger991) {
//   0% {
//     visibility: visible;
//     font-size: 22px;
//   }

//   100% {
//     font-size: 28px;
//     visibility: visible;
//   }
// }

// @include keyframes(textgetbigger767) {
//   0% {
//     visibility: visible;
//     font-size: 16px;
//   }

//   100% {
//     font-size: 22px;
//     visibility: visible;
//   }
// }

// @include keyframes(textgetbigger464) {
//   0% {
//     visibility: visible;
//     font-size: 16px;
//   }

//   100% {
//     font-size: 18px;
//     visibility: visible;
//   }
 }
.contactBtn{
  width: 156px;
  height: 40px;
border:none;

  border-radius: 10px ;
  text-decoration: none;
font-family: Poppins;
font-size: 18px;
font-weight: 500;
line-height: 40px;
text-align: center;

background: linear-gradient(90deg, #7FF593 0%, #68EAAE 51.56%, #53DFC7 100%);
color: white;
display: block;
  position: absolute;
  top: 35px;
  right: 80px;
  @media screen and (max-width: 1200px) {
    right: 40px;
    width: 146px;
    height: 40px;
    font-size: 16px;
  }
  @media screen and (max-width: 1114px) {
    right: 10px;
    width: 146px;
    height: 40px;
    font-size: 16px;
  }
  @media screen and (max-width: 991px) {
   
display: none;
  }
  @media screen and (max-width: 767px) {
   display: none;

  }
  @media screen and (max-width: 474px) {
    display: none;
 
   }
}
.contactBtn:hover{
  color:white;
}
.imgMenuu{

  width:80% ;
  height:80% ;
  @media screen and (max-width: 474px) {
  width:75%;
  height: 75%;
  }
}
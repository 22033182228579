.CustomProjects {
 // overflow: hidden;
  width: 100%;
  padding: 20px 0px;
  margin-top:25px;
.project-Col{

 // width:100%;
  height:500px;
  position:relative;
  @media screen and (max-width: 767px) {
    height:450px;
      }
      @media screen and (max-width: 474px) {
    height:350px;
      }
}
  
    
    .titlepro {
      margin-top: 0px;
      p {
        font-size: 28px;
        font-weight: 600;
        font-family: Poppins;
        color: white;
        @media screen and (max-width: 991px) {
          font-size: 20px;
          font-weight: 600;
          line-height: 15px;
        }
        @media screen and (max-width: 474px) {
          font-size: 18px;
          font-weight: 600;
          line-height: 15px;
        }
      }
      
      span {
        
        margin-right: 10px;
        padding: 5px 15px;
        border-radius: 10px;
        font-size: 12px;
        font-weight: 700;
        font-family: "Poppins";
        background: linear-gradient(90deg, #7ff593, #68eaae, #53dfc7);
        @media screen and (max-width: 1200px) {
          font-size: 14px;
        }
        @media screen and (max-width: 568px) {
          font-size: 12px;
        }
      }
    }
  }


.titlepro1 {
 
    font-size: 20px;
    font-weight: 400;
    font-family: Poppins;
    color: black;
    @media screen and (max-width: 991px) {
      font-size: 18px;
      font-weight: 400;
      line-height: 15px;
    }
    @media screen and (max-width: 474px) {
      font-size: 16px;
      font-weight: 400;
      line-height: 15px;
    
  }}
.nodata {
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  margin-top: 30px;
}
.link-col{
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: flex;
  gap: 10px;
}
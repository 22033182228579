.ClientReviewSlider {
  margin-bottom: 200px;
  @media screen and (max-width: 1200px) {
    margin-bottom: 130px;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 0px;
  }
  .clintImg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0px;
    @media screen and (max-width: 767px) {
      top: -70px;
    }
    @media screen and (max-width: 586px) {
      top: -24px;
      width: 85%;
      right: 10%;
    }
  }
  .rank {
    position: absolute;
    width: 50%;
    height: 30%;
    background-color: black;
    border-radius: 10px;
    bottom: -10%;
    @media (max-width: 991px) {
      height: 20%;
      bottom: 0%;
      right: -17%;
      width: 60%;
    }
    @media (max-width: 767px) {
      height: 15%;
      bottom: 30%;
      right: -12%;
      width: 55%;
    }
    @media (max-width: 586px) {
      height: 27%;
      bottom: 0%;
      right: 4%;
      width: 55%;
    }
    @media (max-width: 348px) {
      height: 27%;
      bottom: 7%;
      right: 4%;
      width: 65%;
    }
    right: 0;
    .name {
      color: white;
      font-family: "Poppins";
      padding: 20px;
      font-size: 20px;
      font-weight: 700;
      @media screen and (max-width: 1400px) {
        padding: 25px;
        font-size: 21px;
      }
      @media screen and (max-width: 1200px) {
        padding: 20px;
        font-size: 18px;
      }
      @media screen and (max-width: 991px) {
        padding: 14px !important;
        font-size: 13px;
      }
      @media screen and (max-width: 768px) {
        padding: 12px !important;
        font-size: 10px;
      }
      @media screen and (max-width: 586px) {
        padding: 18px !important;
        font-size: 16px;
      }

      .leftrank {
        display: inline-block;
        width: 50%;
      }
      .star {
        display: inline-block;
        width: 50%;
        color: #f8d72a;
        text-align: right;

        img {
          width: 20px;
          margin-bottom: 5px;
          @media screen and (max-width: 767px) {
            width: 13px;
          }
          @media screen and (max-width: 586px) {
            width: 20px;
          }
          @media screen and (max-width: 464px) {
            width: 16px;
          }
        }
      }
    }
    .title {
      display: inline-block;
      font-size: 16px;
      font-weight: 500;
      width: 60%;
      margin-top: 10px;

      @media screen and (max-width: 1400px) {
        font-size: 13px;
      }
      @media screen and (max-width: 1200px) {
        font-size: 12px;
      }
      @media screen and (max-width: 991px) {
        margin-top: 0px;
        font-size: 9px;
      }
      @media screen and (max-width: 767px) {
        font-size: 6px;
      }
      @media screen and (max-width: 586px) {
        font-size: 12px;
        margin-top: 0px;
      }

      @media screen and (max-width: 468px) {
        font-size: 12px;
      }
      @media screen and (max-width: 430px) {
        font-size: 11px;
      }
      @media screen and (max-width: 386px) {
        font-size: 8px;
      }
    }
  }
  .review {
    position: absolute;
    top: 0px;
    left: 0px;
    font-family: "Poppins";
    color: black;
    height: fit-content;
    margin-left: 20px;

    .title {
      font-size: calc(24px + 0.390625vw);
      margin-top: 12%;
      font-weight: 600;
      @media screen and (max-width: 991px) {
        font-size: calc(20px + 0.390625vw);
      }
      @media screen and (max-width: 768px) {
        font-size: calc(16px + 0.390625vw);
      }
      @media screen and (max-width: 586px) {
        font-size: calc(18px + 0.390625vw);
        margin-left: 15%;
      }
      @media screen and (max-width: 380px) {
        margin-left: 5%;
      }
    }
    .body {
      font-family: "Poppins";

      font-size: calc(12px + 0.390625vw);
      font-weight: 400;
      max-width: 600px;
      min-width: 600px;
      @media screen and (max-width: 1200px) {
        max-width: 300px;
        min-width: 300px;
      }
      @media screen and (max-width: 991px) {
        font-size: calc(12px + 0.390625vw);
      }
      @media screen and (max-width: 768px) {
        font-size: calc(7px + 0.390625vw);
        max-width: 200px;
        min-width: 200px;
      }
      @media screen and (max-width: 586px) {
        font-size: calc(11px + 0.390625vw);
        max-width: 300px;
        min-width: 300px;
        margin-left: 15%;
      }
      @media screen and (max-width: 380px) {
        margin-left: 5%;
      }
    }
  }
}

.arrows {
  width: 100%;
  margin: auto;
  text-align: center;
  img {
    cursor: pointer;
    margin: 0 20px;
  }
}
.animate-left {
  animation: moveleft 2s ease-in-out forwards;
}

.animate-right {
  animation: moveright 2s ease-in-out forwards;
  @media screen and (max-width: 586px) {
    animation: moverightmobile 2s ease-in-out forwards;
  }
}

.left {
  height: 100%;
}
@keyframes moveleft {
  0% {
    right: 0px;
    opacity: 1;
  }
  100% {
    right: 400px;

    opacity: 0;
  }
}

@keyframes moveright {
  0% {
    right: 400px;
    opacity: 0;
  }
  100% {
    right: 0px;

    opacity: 1;
  }
}

@keyframes moverightmobile {
  0% {
    right: 400px;
    opacity: 0;
  }
  100% {
    right: 8%;
    opacity: 1;
  }
}

.height-430 {
  height: 430px;
}

.animate-left2 {
  animation: moveleft2 2s ease-in-out forwards;
}

.animate-right2 {
  animation: moveright2 2s ease-in-out forwards;
}

.left {
  height: 100%;
}
@keyframes moveleft2 {
  0% {
    left: 0px;
    opacity: 1;
  }
  100% {
    left: 400px;

    opacity: 0;
  }
}

@keyframes moveright2 {
  0% {
    left: 400px;
    opacity: 0;
  }
  100% {
    left: 0px;
    opacity: 1;
  }
}

.imghighformobile {
  @media screen and (max-width: 586px) {
    height: 360px;
  }
}

.mobileheight {
  @media screen and (max-width: 586px) {
    height: 800px;
  }
}

@import "./mixin.scss";

.herotext {
 
  position: absolute;
  top: 25%;
  left: 17%;
 

  @media screen and (max-width: 474px) {
    left: 11%;
    top:40%
    
  }
  // img {
  //   width: 30%;
  //   margin-left: 50px;
  //   margin-bottom: 20px;
    
  //   @media screen and (max-width: 767px) {
  //     width: 40%;
  //     margin-left: 35px;
  //   }
  //   @media screen and (max-width: 474px) {
  //     width: 50%;
  //     margin-left: 25px;
      
  //   }
  // }
  .slagon {
    margin-top: -20px;
font-size: 20px;
font-weight: 600;
line-height: 20px;
@media screen and (max-width: 1200px) {
  padding-left: 4%;
  font-size: 20px;

}
@media screen and (max-width: 991px) {
  padding-left: 4%;
  font-size: 18px;
  
}
@media screen and (max-width: 767px) {
  padding-left: 4%;
  font-size: 14px;

}
@media screen and (max-width: 474px) {
  padding-left: 0;
  font-size: 12px;
  
}
  }
 
  .herotext-p{
    font-family: "Poppins";
    font-weight: 500;
    font-size: 18px;
    width: 59%;
  

color:white;
    padding-left: 4%;
    text-transform: capitalize;

   padding-top: 125px;
   @media screen and (min-width: 1914px) {
    padding-top: 140px;
   }
   @media screen and (max-width: 1200px) {
    padding-left: 4%;
    padding-top: 75px;
    font-size: 18px;
    width: 58%;
  }
   @media screen and (max-width: 991px) {
    padding-left: 4%;
    padding-top: 70px;
    font-size: 16px;
    width: 58%;
  }
    @media screen and (max-width: 767px) {
      padding-left: 4%;
      padding-top: 30px;
      font-size: 12px;
      width: 61%;
    }
    @media screen and (max-width: 474px) {
      padding-left: 0;
      padding-top: 50px;
      font-size: 11px;
      width:90%;
    }
  }
  p{
    font-family: "Poppins";
    font-weight: 500;
    font-size: 18px;
    width: 59%;
    color: white;
    padding-left: 4%;
    text-transform: capitalize;

    @media screen and (max-width: 767px) {
      padding-left: 5%;
      font-size: 14px;
      width: 80%;
    }
    @media screen and (max-width: 474px) {
      padding-left: 6%;
      font-size: 12px;
      width:90%;
    }
  }
  }

.beforeSlagon{
  position: relative;
margin-bottom: 50px;
margin-top:20px;
@media screen and (max-width: 474px) {
 margin:0;
}
  &:before {
      
      left:-10px;
      top: 0;
      position: absolute;
      content: url(../../../../assets/wbeforeTitle.svg);
      @media screen and (max-width: 1200px) {
        left:-20px;}
    
      @media screen and (max-width: 991px) {
        left:-20px;
      }
      @media screen and (max-width: 767px) {
        content: url(../../../../assets/prefix.svg);
        left:-12px;
      }
      @media screen and (max-width: 474px) {
        content: url(../../../../assets/prefixmini.svg);
        left:-21px;
        top:-3px;
      }
    
     
    }
 
}

.heroscroll {
  position: absolute;
  bottom: 10%;
  left: 18%;
  @include animate(scrol, 3s, linear, infinite, forwards);
 

  img {
    width: 80%;
    @media screen and (max-width: 767px) {
      width: 70%;
    }
    @media screen and (max-width: 474px) {
      width: 60%;
      display: none;
    }
  }
}

@include keyframes(scrol) {
  0% {
    bottom: 10%;
  }

  50% {
    bottom: 13%;
  }
  100% {
    bottom: 10%;
  }
}

// .video-container {
//    //z-index: -100;
//   // width:100%;
//   // height:100vh;
//   // overflow:hidden;
//   // position:absolute;
//   // top:0;
//   // left:0;



//  //position: relative;
//   // padding-bottom: 56.25%; /* 16:9 */
//   // padding-top: 25px;
//   // height: 0;


//   }
  
  .video-bg{
  
 
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  height: 100%;
  object-fit: cover;
// display: none;
// opacity: 1;
//             transition: opacity 1s;
  @media screen and (max-width: 474px) {
    height: 150%;
   // width:111%;
  }
  
  }
  .bgcHero{
    z-index: -2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  height: 100%;

 // position: relative;
  background-size: cover;
  background-image:url("../../../../assets/BasicBackground.svg");
  background-repeat: none;

;
@media screen and (max-width: 474px) {
  height: 150%;
 // width:111%;
}

  }

 
  

.navheader{
  @media screen and (max-width: 474px) {
    margin-right: 100px;
}
}
.logo1{
    width: 65px;
    height: 75px;
    margin: 10px 0 0 20px;
    object-fit:contain;
   
    @media screen and (max-width: 767px) {
      width: 75px;
      height: 75px;
    }
  }
  .close1{
    width: 50px;
    height: 50px;
    position: absolute;
    top: 45px;
    right: 40px;
    cursor: pointer;
    @media screen and (max-width: 767px) {
      width: 40px;
      height: 40px;
    }

    @media screen and (max-width: 474px) {
      width: 40px;
      height: 40px;
    }
  }
  .menu1{
    position: absolute;
    top: 22px;
    right: 10px;
    height: 80px;
    cursor: pointer;
    display:none;
    @media screen and (max-width: 991px) {
      height: 50px;
      display:block;
    }
    @media screen and (max-width: 767px) {
      height: 50px;
      display:block;
    }
    @media screen and (max-width: 474px) {
   
      display:block;
    }
  }
  .navLink{
  
    display: block;
      position: absolute;
      top: 35px;
      left: 210px;
    
    
      font-family: Poppins;
    font-size: 19px;
    font-weight: 500;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    @media screen and (max-width: 991px) {
      display:none;
      
      }
    @media screen and (max-width: 767px) {
    display:none;
    
    }
    @media screen and (max-width: 474px) {
      display:none;
      
      }
    .navl{
      
     color:black;
      margin-left: 40px;
     }
    
    
    }
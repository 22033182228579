.main-offer-div{
   padding: 45px 30px;;
     position:absolute;
gap:40px;
    top:-31px;
    height:547px;
    left:70px;
    width: 40%;
    background:
      linear-gradient(90deg, #7FF593 0%, #68EAAE 51.56%, #53DFC7 100%);
    display: flex;
    flex-direction: column;

    font-family: Poppins;
font-size: 30px;
font-weight: 400;
line-height: 44.31px;
text-align: left;
color: #00000066;


  
}
.main-offer-div::before{
    content: url(../../../assets/toptraingle.svg);
    position:absolute;
    top:0px;
    left:-54px;
}
.main-offer-div::after{
    content: url(../../../assets/bottomraingle.svg);
    position:absolute;
    bottom:-12px;
    right:-54px;
}



.webb {
  position: absolute;
  display: block;
  margin-left:50%;
  animation-name: webbanimation !important;
  animation-duration: 3s;
  animation-iteration-count: 1;
  @media screen and (min-width: 767px) {
    display: block !important;
  }

  @media screen and (max-width: 767px) {
    display: none !important;
  }
  @media screen and (max-width: 474px) {
    display: none !important;
  }
}
@keyframes webbanimation {
  0%   {left:-100px; top:0px;opacity: 0;}
 
  100% { left:0px; top:0px;opacity: 1;}

  
}



.webb1 {
  position: absolute;
  display: block;
  margin-left:50%;
  animation-name: webbanimation1 !important;
  animation-duration: 3s;
  animation-iteration-count: 1;
  @media screen and (min-width: 767px) {
    display: block !important;
  }

  @media screen and (max-width: 767px) {
    display: none !important;
  }
  @media screen and (max-width: 474px) {
    display: none !important;
  }
}
@keyframes webbanimation1 {
  0%   {left:-100px; top:0px;opacity: 0;}
 
  100% { left:0px; top:0px;opacity: 1;}

  
}

.webb2 {
  position: absolute;
  display: block;
  margin-left:50%;
  animation-name: webbanimation2 !important;
  animation-duration: 3s;
  animation-iteration-count: 1;
  @media screen and (min-width: 767px) {
    display: block !important;
  }

  @media screen and (max-width: 767px) {
    display: none !important;
  }
  @media screen and (max-width: 474px) {
    display: none !important;
  }
}
@keyframes webbanimation2 {
  0%   {left:-100px; top:0px;opacity: 0;}
 
  100% { left:0px; top:0px;opacity: 1;}

  
}
.webb3 {
  position: absolute;
  display: block;
  margin-left:50%;
  animation-name: webbanimation3 !important;
  animation-duration: 3s;
  animation-iteration-count: 1;
  @media screen and (min-width: 767px) {
    display: block !important;
  }

  @media screen and (max-width: 767px) {
    display: none !important;
  }
  @media screen and (max-width: 474px) {
    display: none !important;
  }
}
@keyframes webbanimation3 {
  0%   {left:-100px; top:0px;opacity: 0;}
 
  100% { left:0px; top:0px;opacity: 1;}

  
}
.webb4 {
  display: block;
  position: absolute;
  margin-left:50%;
  animation-name: webbanimation4 !important;
  animation-duration: 3s;
  animation-iteration-count: 1;
  @media screen and (min-width: 767px) {
    display: block !important;
  }

  @media screen and (max-width: 767px) {
    display: none !important;
  }
  @media screen and (max-width: 474px) {
    display: none !important;
  }
}
@keyframes webbanimation4 {
  0%   {left:-100px; top:0px;opacity: 0;}
  /* 50%   {opacity: 0.5;} */
  100% { left:0px; top:0px;opacity: 1;}

  
}
.main-offer-div1{
display: block;
    margin-top:50px;
    background-color: #F6F6F6;
    position: relative;
    width: 100%;
    height:485px;
    display: flex;
    gap: 30px;
    @media screen and (max-width: 767px) {
      display: none !important;
    }
}

.main-offer-div-mobile{

  
  display: none;
  @media screen and (max-width: 767px) {
    display: block !important;
  }
}
.unHovermobile {
  padding:15px;
  position: relative;
  text-align: left;

  color: rgba(0, 0, 0, 0.7);

  height: 54px;
  width: 100%;
  font-family: "Poppins";
  font-size: 19px;
  font-weight: 700;

  cursor: pointer;
  background: linear-gradient(90deg, #7FF593 0%, #68EAAE 51.56%, #53DFC7 100%);

  @media screen and (max-width: 1200px) {
    font-size: 19px;
  }
  @media screen and (max-width: 991px) {
    font-size: 18px;
  }
  @media screen and (max-width: 767px) {
    font-size: 17px;
  }
  @media screen and (max-width: 474px) {
    font-size: 16px;
  }
}
.Hovermobile {
  padding:15px;
  position: relative;
  text-align: left;

  color: black;
  height: 54px;
  width: 100%;
  font-family: "Poppins";
  font-size: 19px;
  font-weight: 700;
  background: linear-gradient(90deg, #7FF593 0%, #68EAAE 51.56%, #53DFC7 100%);
 
  padding-left: 15px;
  
  cursor: pointer;
  /* transition: 0.3s ease-in 0.3s; */
  transition: all .8s ease-in-out;
  @media screen and (max-width: 1200px) {
    font-size: 19px;
  }
  @media screen and (max-width: 991px) {
    font-size: 18px;
  }
  @media screen and (max-width: 767px) {
    font-size: 17px;
  }
  @media screen and (max-width: 474px) {
    font-size: 16px;
  }
}
.Hovermobile::before{
  content: url(../../../assets/topmtraingle.svg);
  position:absolute;
  top:-19px;
  left: 0;
}
.Hovermobile::after{
  content: url(../../../assets/bottommtrangle.svg);
  position:absolute;
  bottom:-26px;
  right: 0;
}
.unHovermobile::before{
  content: url(../../../assets/topmtraingle.svg);
  position:absolute;
  top:-19px;
  left:0;
}
.unHovermobile::after{
  content: url(../../../assets/bottommtrangle.svg);
  position:absolute;
  bottom:-26px;
  right:0px;
}
.main{
    max-width: 1200px ;
    position: relative;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    overflow-x: hidden;
    margin-bottom:10px;
    @media screen and (max-width:991px){
      margin-top: -40px;
    
    
    }
    @media screen and (max-width:747px){
      margin-top: -50px;
    
    
    }
@media screen and (max-width:474px){
  margin-top:90px;
  padding: 0.5rem;


}
    
  }
  .mainabout{
    max-width: 1200px ;
    position: relative;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    overflow-x: hidden;
    margin-bottom:10px;
    @media screen and (max-width:991px){
      margin-top: -40px;
    
    
    }
    @media screen and (max-width:747px){
      margin-top: -50px;
    
    
    }
@media screen and (max-width:474px){
  margin-top:20px;
  padding: 1rem;


}
    
  }
  .slidede img {

   cursor: pointer;
     width: 247px;
      height: 60px;
      animation: scroll 30s linear infinite;
    }
  
  
  .slide-trackck {
    height: 110px;
    width: 100%;
    display: flex;
   // gap: 3em;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg, #7FF593 0%, #68EAAE 51.56%, #53DFC7 100%);

  }
  
  .sliderer {
 
    background-color: white;
    padding-bottom: 60px; 
  }
  
  @keyframes scroll {
    from {
      transform: translateX(800px);
    }
    to {
      transform: translateX(-700%);
    }

    // 0% {transform: translateX(0);}
    // 100% {transform: translatex(-1000%)}
  }

  // @keyframes scroll {
  //   0% {
  //     transform: translateX(0);
  //   }
  //   100% {
  //     transform: translateX(calc(-250px * 7));
  //   }
  // }
  
  // .sliderer {
  //   background: white;
  //   /* box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125); */
  //   /* height: 100px; */
  //   margin: auto;
  //   overflow: hidden;
  //   position: relative;
  //   width: 100%;
  
    // &::before,
    // &::after {
    //   content: "";
    //   height: 100px;
    //   position: absolute;
    //   width: 200px;
    //   z-index: 2;
    //   @media screen and (max-width: 474px) {
    //     height: 50px;
    //     width: 100px;
    //   }
    // }
  
    // &::after {
    //   right: 0;
    //   top: 0;
    //   transform: rotateZ(180deg);
    // }
  
    // &::before {
    //   left: 0;
    //   top: 0;
    // }
  
    // .slide-trackck {
    //   animation: scroll 40s linear infinite;
    //   display: flex;
    //   width: calc(250px * 7);
    //   @media screen and (max-width: 474px) {
    //     width: calc(125px * 7);
    //   }
    // }
  
    .slidede {
      height: 60px;
      width: 247px;
      background-color: white;
      @media screen and (max-width: 474px) {
        height: 50px;
        width: 125px;
      }
    
    }

    .sliderer:hover .slidede img {
      animation-play-state: paused;
    }



    @keyframes scroll {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(calc(-250px * 7));
      }
    }
    
    .slider-home {

      
      background:#F4F4F4;
      ;
      /* box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125); */
      /* height: 100px; */
      margin: auto;
      overflow: hidden;
      position: relative;
      width: 100%;
      margin-bottom: 70px;
    
      &::before,
      &::after {
        content: "";
        //height: 100px;
        position: absolute;
        width: 200px;
        z-index: 2;
        @media screen and (max-width: 474px) {
         // height: 50px;
          width: 100px;
        }
      }
    
      &::after {
        right: 0;
        top: 0;
        transform: rotateZ(180deg);
      }
    
      &::before {
        left: 0;
        top: 0;
      }
    
      .slide-track-home {
        animation: scroll 40s linear infinite;
        display: flex;
        width: calc(250px * 14);
        @media screen and (max-width: 474px) {
         // width: calc(125px * 7);
        }
      }
    
      .slide-home {
        //height: 100px;
       // width: 250px;
        @media screen and (max-width: 474px) {
         // height: 50px;
          //width: 125px;
        }
      }
    }
    .slide-home{
      display: flex;
    justify-content: center;

    gap: 10px;
    width: 480px;
    background-color: transparent;
    @media screen and (max-width: 474px) {
      
          width: 100%;
          min-height: 50px;
          overflow: hidden;
    }
    }
    
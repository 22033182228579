@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

.motion1 {
  transition: linear;
  animation-name: example;
  animation-duration: 1.5s;
  animation-delay: 0.1s;

  animation-iteration-count: 1;
}
@keyframes example {
  from {
    opacity: 0;
    transform: translateY(-300px) translateX(-300px);
  }
  to {
    opacity: 1;
    transform: translateY(0px) translateX(0px);
  }
}
.motion2 {
  transition: linear;
  animation-name: example2;
  animation-duration: 1.5s;
  animation-delay: 0.5s;
  animation-iteration-count: 1;
}
@keyframes example2 {
  from {
    opacity: 0;
    transform: translateY(-300px) translateX(-700px);
  }
  to {
    opacity: 1;
    transform: translateY(0px) translateX(0px);
  }
}
@media screen and (max-width: 1200px) {
  @keyframes example2 {
    from {
      opacity: 0;
      transform: translateY(-300px) translateX(-600px);
    }
    to {
      opacity: 1;
      transform: translateY(0px) translateX(0px);
    }
  }
}
@media screen and (max-width: 991px) {
  @keyframes example2 {
    from {
      opacity: 0;
      transform: translateY(-300px) translateX(-500px);
    }
    to {
      opacity: 1;
      transform: translateY(0px) translateX(0px);
    }
  }
}
@media screen and (max-width: 767px) {
  @keyframes example2 {
    from {
      opacity: 0;
      transform: translateY(-300px) translateX(-400px);
    }
    to {
      opacity: 1;
      transform: translateY(0px) translateX(0px);
    }
  }
}
@media screen and (max-width: 464px) {
  @keyframes example2 {
    from {
      opacity: 0;
      transform: translateY(-300px) translateX(-300px);
    }
    to {
      opacity: 1;
      transform: translateY(0px) translateX(0px);
    }
  }
}

.motion3 {
  transition: linear;
  animation-name: example3;
  animation-duration: 1.5s;
  animation-delay: 1s;
  animation-iteration-count: 1;
}
@keyframes example3 {
  from {
    opacity: 0;
    transform: translateY(-300px) translateX(-900px);
  }
  to {
    opacity: 1;
    transform: translateY(0px) translateX(0px);
  }
}
@media screen and (max-width: 1200px) {
  @keyframes example3 {
    from {
      opacity: 0;
      transform: translateY(-300px) translateX(-700px);
    }
    to {
      opacity: 1;
      transform: translateY(0px) translateX(0px);
    }
  }
}
@media screen and (max-width: 991px) {
  @keyframes example3 {
    from {
      opacity: 0;
      transform: translateY(-300px) translateX(-600px);
    }
    to {
      opacity: 1;
      transform: translateY(0px) translateX(0px);
    }
  }
}
@media screen and (max-width: 767px) {
  @keyframes example3 {
    from {
      opacity: 0;
      transform: translateY(-300px) translateX(-400px);
    }
    to {
      opacity: 1;
      transform: translateY(0px) translateX(0px);
    }
  }
}
@media screen and (max-width: 464px) {
  @keyframes example3 {
    from {
      opacity: 0;
      transform: translateY(-300px) translateX(-300px);
    }
    to {
      opacity: 1;
      transform: translateY(0px) translateX(0px);
    }
  }
}
.motion4 {
  transition: linear;
  animation-name: example3;
  animation-duration: 1.5s;
  animation-delay: 1.5s;
  animation-iteration-count: 1;
}
@keyframes example4 {
  from {
    opacity: 0;
    transform: translateY(-300px) translateX(-1100px);
  }
  to {
    opacity: 1;
    transform: translateY(0px) translateX(0px);
  }
}
@media screen and (max-width: 1200px) {
  @keyframes example4 {
    from {
      opacity: 0;
      transform: translateY(-300px) translateX(-900px);
    }
    to {
      opacity: 1;
      transform: translateY(0px) translateX(0px);
    }
  }
}
@media screen and (max-width: 991px) {
  @keyframes example4 {
    from {
      opacity: 0;
      transform: translateY(-300px) translateX(-700px);
    }
    to {
      opacity: 1;
      transform: translateY(0px) translateX(0px);
    }
  }
}
@media screen and (max-width: 767px) {
  @keyframes example4 {
    from {
      opacity: 0;
      transform: translateY(-300px) translateX(-500px);
    }
    to {
      opacity: 1;
      transform: translateY(0px) translateX(0px);
    }
  }
}
@media screen and (max-width: 464px) {
  @keyframes example4 {
    from {
      opacity: 0;
      transform: translateY(-300px) translateX(-300px);
    }
    to {
      opacity: 1;
      transform: translateY(0px) translateX(0px);
    }
  }
}
.cardMargin{
  @media screen and (max-width:474px){
margin-left:20px;
  
  
  }
}

.Hero {
	.navBar {
		.logo-image {
			width: 80px;
			margin: auto;
		}
	}

	// background-image: url("../img/HomePage\ BG.svg");
	background-size: cover;
	height: 100vh;
	width: 100%;
	background-position: right;
	background-repeat: no-repeat;

	@media screen and (max-width: 991px) {
		background-size: cover;
	}
	@media screen and (max-width: 767px) {
		background-size: cover;
	}
	.bolkfont {
		font-family: "bolk" !important;
	}
	.custom-padding {
		padding-left: 113px;
		@media screen and (max-width: 991px) {
			padding-left: 65px !important;
		}
	}
	.text-div {
		width: 760px;
		margin:25px auto;
		position: relative;
		@media screen and (max-width: 1200px) {
			width: 600px;
		}
		@media screen and (max-width: 991px) {
			width: 400px;
		}
		@media screen and (max-width: 767px) {
			width: 215px !important;
		}
		.hero-text {
			text-transform: uppercase;
			text-align: left;
			color: white;
			font-size: 70px;
			line-height: 75px;
			@media screen and (max-width: 1200px) {
				font-size: 50px;
				line-height: 60px;
			}
			@media screen and (max-width: 991px) {
				font-size: 30px;
				line-height: 50px;
			}
			@media screen and (max-width: 767px) {
				font-size: 30px;
				line-height: 40px;
			}
		}
	}
	.circle {
		width: 120px;
		height: 120px;
		@media screen and (max-width: 991px) {
			width: 90px !important;
		}
		@media screen and (max-width: 767px) {
			width: 50px !important;
		}
	}
	.circle2 {
		width: 91px;
		height: 120px;
		position: absolute;
		top: -58px;
		left: 19px;
		animation: rfloat 4s infinite;
		animation-delay: 2.4s;
		@media screen and (max-width: 991px) {
			width: 60px;
			left: 42px;
		}
	}
	.main-color{
		background-color:"hsla(130, 86%, 73%, 1)";
	}
	.animation-left-buttom {
		animation: float 4s infinite;
		position: absolute;
		left: 50px;
		bottom: 40px;
		@media screen and (max-width: 767px) {
			left: 50px;
			bottom: 20px;
		}
	}

	.animation-right-buttom {
		animation: float 4s infinite;
		position: absolute;
		right: 50px;
		bottom: 40px;
		@media screen and (max-width: 767px) {
			right: 50px;
			bottom: 20px;
		}
	}

	.widthed-circle {
		display: inline-block;
		width: 120px;
		height: 75px;
		border-radius: 50px;
		border: none;
		margin-left: 13px;
		animation: slide 5s infinite;
		& > img {
			margin-bottom: 12px;
			@media screen and (max-width: 991px) {
				margin-bottom: 8px;
			}
		}
		@media screen and (max-width: 991px) {
			display: inline-block;
			width: 110px !important;
			height: 26px !important;
			border-radius: 50px !important;
			border: none !important;
			margin-left: 0px !important;
		}
	}

	.widthed-circle-2 {
		display: inline-block;
		width: 120px;
		height: 75px;
		border-radius: 50px;
		border: none;
		margin-left: 13px;
		//animation: slide 5s infinite;
		& > img {
			margin-bottom: 12px;
			@media screen and (max-width: 991px) {
				margin-bottom: 8px;
			}
		}
		@media screen and (max-width: 991px) {
			display: inline-block;
			width: 110px !important;
			height: 26px !important;
			border-radius: 50px !important;
			border: none !important;
			margin-left: 0px !important;
		}
	}

	.circle3 {
		width: 120px;
		height: 120px;
		position: absolute;
		right: -36px;
		bottom: -43px;
		animation: rrot 5s infinite;

		&:hover {
			animation: rot 5s infinite;
		}

		@media screen and (max-width: 991px) {
			width: 75px;
			right: 28px;
		}
		@media screen and (max-width: 767px) {
			width: 60px;
			left: -35px;
		}
	}
	.circle4 {
		width: 300px;
		position: absolute;
		right: -107px;
		bottom: -122px;
		z-index: 3;
		@media screen and (max-width: 1200px) {
			width: 280px;
		}
		@media screen and (max-width: 991px) {
			width: 250px;
		}
		@media screen and (max-width: 767px) {
			width: 200px;
		}
		@media screen and (max-width: 464px) {
			width: 200px;
			bottom: -70px;
		}
	}
	.circle5 {
		width: 40px;
		position: absolute;
		right: 16px;
		bottom: 70px;
	}
	.circle7 {
		display: none;
		width: 40px;
		height: 40px;
		@media screen and (max-width: 767px) {
			display: block;
			position: absolute !important;
			right: 20px;
			top: 200px;
			animation: rfloat 4s infinite;
		}
		@media screen and (max-width: 464px) {
			display: none !important;
		}
	}
	.circle8 {
		display: none;
		width: 40px;
		height: 40px;
		@media screen and (max-width: 767px) {
			display: block;
			position: absolute !important;
			right: 20px;
			top: 200px;
			animation: float 4s infinite;
		}
		@media screen and (max-width: 464px) {
			display: none !important;
		}
	}
	.circle9 {
		display: none;
		width: 40px;
		height: 40px;
		@media screen and (max-width: 767px) {
			display: block;
			position: absolute !important;
			left: 30px;
			top: 150px;
			animation: float 4s infinite;
			animation-delay: 2.4s;
		}
		@media screen and (max-width: 464px) {
			display: none !important;
		}
	}

	.nav-logo-div {
		width: 100%;
		height: 80px;

		img {
			width: 80px;
			height: 100%;
			margin-left: 20px;
			@media screen and (max-width: 360px) {
				width: 60px !important;
			}
		}
	}

	.nav-menu-div {
		width: 100%;
		height: 40px;
		margin-top: 20px;
		cursor: pointer;

		img {
			width: 40px;
			height: 100%;
			@media screen and (max-width: 360px) {
				width: 30px !important;
			}
		}
	}

	.menu {
		animation: fade-menu 2s ease;
	}

	.hero-navbar {
		width: 80%;
	}

	.nav-anchor {
		color: white !important;
		margin: auto;
		cursor: pointer;
	}

	.fadding-word-2 {
		animation: fade-word 5s ease-in 1s infinite;
	}
}

$rotation-size: 3px;
$timing: 1.2s;

@keyframes fade-word {
	0% {
		opacity: 0.1;
	}

	25% {
		opacity: 0.6;
	}
	50% {
		opacity: 1;
	}

	75% {
		opacity: 0.4;
	}

	100% {
		opacity: 0.1;
	}
}

@keyframes fade-menu {
	from {
		opacity: 0.1;
	}
}

@-webkit-keyframes float {
	from {
		-webkit-transform: rotate(0deg) translate3d(10px, 0, 0) rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg) translate3d(10px, 0, 0) rotate(-360deg);
	}
}

@keyframes float {
	from {
		transform: rotate(0deg) translate3d(20px, 0, 0) rotate(0deg);
	}

	to {
		transform: rotate(360deg) translate3d(20px, 0, 0) rotate(-360deg);
	}
}

@keyframes rfloat {
	from {
		transform: rotate(360deg) translate3d(20px, 0, 0) rotate(-360deg);
	}

	to {
		transform: rotate(0deg) translate3d(20px, 0, 0) rotate(0deg);
	}
}

@-webkit-keyframes float-subpixel {
	from {
		-webkit-transform: rotate(-0.001deg) translate3d(5px, 0, 0)
			rotate(-0.001deg);
	}

	to {
		-webkit-transform: rotate(360.001deg) translate3d(3px, 0, 0)
			rotate(-360.001deg);
	}
}

@keyframes float-subpixel {
	from {
		transform: rotate(-0.001deg) translate3d(3px, 0, 0) rotate(-0.001deg);
	}

	to {
		transform: rotate(360.001deg) translate3d(3px, 0, 0) rotate(-360.001deg);
	}
}

@keyframes rot {
	50% {
		transform: rotate(180deg);
	}
	100% {
		transform: rotate(180deg);
	}
}

.navbar-toggler {
	background-color: white !important;
	z-index: -999999999;
}

@keyframes slide {
	25% {
		margin-left: 20px;
	}
	50% {
		margin-left: 60px;
	}
	// 75% {
	// 	width: 190px;
	// }
	// 100% {
	// 	width: 60px;
	// }
}

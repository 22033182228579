$primary-color: #53dfc7;
.nav {
  width: 100%;
  padding-top: 20px;
  font-size: 18px;
  font-weight: 500;
  color: #000;
  font-family: "Poppins";
  overflow: auto;
  white-space: nowrap;
  &::-webkit-scrollbar {
    display: none;
  }
  ul {
    list-style: none;
    margin-bottom: 0;
    @media screen and (max-width: 568px) {
      width: fit-content;
      height: 60px;
    }
    li {
      margin: 20px 0;
      cursor: pointer;
      @media screen and (max-width: 568px) {
        display: inline-block;
        margin: 20px 20px;
      }
      &:hover {
        color: $primary-color;
        font-weight: 700;
        &::before {
          margin-right: 10px;

          content: url("../../../assets/projects/lefticonprojectnav.svg");
        }
      }
    }
    .selected {
      color: $primary-color;
      font-weight: 700;
      &::before {
        margin-right: 10px;
        display: inline-block;
        content: url("../../../assets/projects/lefticonprojectnav.svg");
      }
    }
  }
}

.pagingbox {
  display: inline-block;
  width: 40px;
  height: fit-content;
  border: 1px solid #000;
  border-radius: 5px;
  text-align: center;
}
.pagingbutton {
  cursor: pointer;
}
// .left-content::after {
  
// content: url('../../../assets/afterRightContent.svg');


// }
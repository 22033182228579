.slider {
  width: 480px;
 
  @media screen and (max-width: 474px) {
    width: auto;
  
  }

}
.rankslider {
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.07);

  width: 480px;
  height: 120px;
  position: absolute;
  right: 6%;
  bottom: -50px;
  border-radius: 5px;
background-color: white;
  @media screen and (max-width: 474px) {
    left: 0;
  right: 0;
  margin: 0 auto;
    // right: 8%;
    // bottom: -60px;
     width: 95%;
     min-height: 100px;
     overflow: hidden;
     bottom: -170px;
  }
  // @media screen and (max-width: 442px) {
  //   right: 2%;
  //   bottom: -60px;
  //   width: 400px;
  //   height: 95px;
  
  // }
}

.slide {

  display: flex;
  justify-content: left;
  align-items: left;
  gap:10px;
  width: 480px;
//height: 120px;

  background-color:white;
  border-radius: 8px;
  @media screen and (max-width: 474px) {
    // width: 400px;
    // height: 95px;
    width: 100%;
    min-height: 100px;
    overflow: hidden;
  
  }
  .imgrank {
    width: 30%;
   // height: 30%;
    @media screen and (max-width: 474px) {
      width: 25%;
    
    }
    img {
      // border-radius: 100%;
     // height: 85px;
     width: 100px;
    margin: 5px 0 0 10px;
      @media screen and (max-width: 474px) {
        margin: 6px 0 0 8px;
        width: 97px;
    height: 103px;
      
      }
    }
  }
  .text {
     width: 100%;
    // height: 100%;
    // @media screen and (max-width: 474px) {
      // width: 80%;
    
   // }
    p {
     // width: 100%;
      margin: 0;
      height: 25px;
      color: black;
      padding: 12px 0 0 10px;
      @media screen and (max-width: 474px) {
        padding: 8px 0 0 10px;
       
      }
      .name {
        font-size: 18px;
        display: inline-block;
        //width: 60%;
        font-weight: bold;
        @media screen and (max-width: 474px) {
          font-size: 16px;
        
        }
      }
      .rank {
        font-size: 18px;
        text-align: end;
        color: #f8d72a;
        display: inline-block;
        width: 35%;
        @media screen and (max-width: 474px) {
          font-size: 16px;
        
        }
        img {
          width: 20px;
          height: 20px;
          display: inline;
          padding-bottom: 4px;
        }
      }
    }
    .position {
      padding-top: 5px;
      @media screen and (max-width: 474px) {
        padding-top: 0px;
       width: auto;
       word-wrap: break-word;
       // min-height:70px;
      
      }
      span {
        font-size: 12px;
        display: block;
        margin-top: 6px;
        //margin-bottom: 5px !important;
        @media screen and (max-width: 474px) {
          margin-bottom: 0px !important;
          margin-top: 4px;
        }
      
      }
      .sscompany{
        font-family: Poppins;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        
        display: inline-block;

        margin-bottom: 0px !important;
        @media screen and (max-width: 474px) {
          font-size: 13px;
          //width: 300px;
          line-height: 18px;
        width:250px;
        
        }
        @media screen and (max-width: 373px) {
          font-size: 13px;
          //width: 300px;
          line-height: 18px;
        width:210px;
        
        }
      }
    }
  }
}

// @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(./fonts/Poppins-Regular.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Agency FB', sans-serif;
    src: local('Agency FB'), url(./fonts/AGENCYB.TTF) format('truetype');
  }
  @font-face {
    font-family: 'Inter';
    src: local('Inter'), url(./fonts/Inter-Regular.ttf) format('truetype');
  }
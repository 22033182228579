.footer {
  width: 100%;
  background-image: url(../../assets/footerback.svg);
  color: white;
  position: relative;
  padding-top: 10px;
  .form {
    justify-content: center;
    display: flex;
    width: 55%;
    position: absolute;
    top: -38px;
    background-color: #fff;
    z-index: 9999;
    left: 20%;
    border-radius: 10px;
    color: #000;
    padding: 7px;
    @media screen and (max-width: 864px) {
      left: 30%;
    }
    @media screen and (max-width: 474px) {
      left: 2.5%;
      width: 95%;
      padding: 0px;
      
    }
    box-shadow: 1px 1px 5px #888;

    p {
      font-size: 20px;
      padding: 14px 0px;
      display: inline-block;
      margin: 0;
      @media screen and (max-width: 1200px) {
        font-size: 19px;
      }
      @media screen and (max-width: 999px) {
        font-size: 16px;
        padding: 8px;
      }
      @media screen and (max-width: 474px) {
        font-size: 14px;
        
      }
    }
    fieldset {
      display: inline-block;
    }
    .fieldInput {
      border-bottom: 1px solid #000;
      width: 48%;
      height: 35px;
      margin-left: 2%;
      position: relative;
      overflow: hidden;
      padding-bottom: 5px;
      @media screen and (max-width: 767px) {
        width: 90%;
      }
      @media screen and (max-width:474px) {
        width: 95%;
        display: flex;
        justify-content: left;
        align-items: flex-start;
        margin-top: 5px;
      }
      .form-input {
        width: 200px;
        background: none;
        border: none;
        padding: 12px 0px;
        font-size: 12px;
        color: #000;
        @media screen and (max-width: 999px) {
          font-size: 10px;
        }

        &:focus {
          outline: none;
        }
      }
      .form-submit {
        color: #000;
        // font-weight: 500;
        font-size: 12px;
        position: absolute;
        right: 0;
        bottom: 4px;
        width: 22%;
        height: 29px;
        border: none;
        border-radius: 10px;
        background: linear-gradient(90deg, #7ff593 0%, #68eaae 51.56%, #53dfc7 100%);
        cursor: pointer;
        @media screen and (max-width: 1200px) {
          font-size: 12px;
        }
        @media screen and (max-width: 999px) {
          font-size: 10px;
        }
        @media screen and (max-width:474px) {
          font-size: 10px;
          width:65px;
          
        }
      }
    }
  }
  .logoff {
    width: 100px;
    height: 100px;
  }
  .logotext {
    width: 80%;
    margin-top: 10px;
    font-family: "Poppins";
    text-transform: capitalize;
    font-size: 17px;
    @media screen and (max-width: 474px) {
      font-size: 15px;
    }
  }
  .linksc{
    margin-top: 54px;
    padding-left: 0;
  }
  .links {
    ul {

      list-style: none;
      margin-top: 54px;
      padding-left: 0;
      @media screen and (max-width: 474px) {
        margin-top: 20px;
      }
      li {
        margin-top: 14px;
       
        color: #fff;
        font-family: "Poppins";
        font-size: 17px;
        cursor: pointer;
        @media screen and (max-width: 474px) {
          font-size: 15px;
        }
      
        &:hover{
          color:grey;
        }
      
      }
    }
  }
  .linkfoo{
    color: white;
    margin-right: 20px;
  }
  .linkfoo:hover{
    
      color:grey;
    
  
  }
  .linkfoo1{
 color: rgba(83, 223, 199, 1);

  }
  .location {
    margin-top: 54px;
    display: flex;
    @media screen and (max-width:474px) {
      margin-top: 24px;
    }
    .img{
      width: 40px;
    }
    .text {
      width: 80%;
      margin-left: 20px;
      font-family: "Poppins";
      font-size: 15px;
      @media screen and (max-width: 474px) {
        font-size: 14px;
      }
    }
  }
  .iconlinks {
    ul {
      margin-top:10px;
      list-style: none;
      display: flex;
      padding: 0;
      li {
        margin: 0 25px 0 0;
        cursor: pointer;
      }
    }
  }
  .connect {
    color: #53dfc7;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
  }

  .copyright {
    width: 100%;
   
    background-size: cover;
    background-repeat: repeat;
    margin-top: 0px;
 
  }
}

.ml-3 {
  margin-left: 30px;
  @media screen and (max-width:474px) {
    margin-left: 15px;
  }
}
.ml-5{
  @media screen and (max-width:474px) {
    margin-left: 20px;
  }
}

.cursor-pointer {
  cursor: pointer;
  @media screen and (max-width:474px) {
  }
}

.border-top {
  border-top: 1px solid #fff;
  padding-top: 20px;
}

.flex-wrap {
  flex-wrap: wrap;
  @media screen and (max-width:474px) {
    font-size: 12px;
    flex-direction: column;
   
  }
}
.flex-wrap1 {
  flex-wrap: wrap;
  @media screen and (max-width:474px) {
    font-size: 12px;
    margin-top: -55px;
  
   
  }
}
::placeholder {
  color: #000;
  opacity: 1; /* Firefox */
}

.blog-main{
display: flex;
flex-direction: column;

}
.blog-img img{
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background-size: cover;
    cursor: pointer;
   
}
.blogs-row{
    @media screen and (max-width:474px){
   margin-top: 20px;
        
    }
}

.blog-data{
    padding: 10px;
    display: flex;
    gap:100px;
    flex-direction: row;

    p{
        font-family: Poppins;
font-size: 15px;
font-weight: 400;
line-height: 27px;
text-align: left;
@media screen and (max-width:474px){
    font-size: 10px;
    
}
    }
    @media screen and (max-width:474px){
    gap:80px;
        
    }
}
.blog-content{
    display: flex;
    flex-direction: column;
}
.blog-title{
  cursor: pointer;
    font-family: Poppins;
font-size: 24px;
font-weight: 500;
line-height: 41.36px;
text-align: left;
color:black;
@media screen and (max-width:991px){
    font-size: 20px;
    line-height: 32px;
  
  
  }
  @media screen and (max-width:747px){
    font-size: 18px;
    line-height: 28px;
  
  
  }
@media screen and (max-width:474px){
    font-size: 16px;
    line-height: 22px;

}
}
.blog-detail{
    font-family: Poppins;
font-size: 18px;
font-weight: 400;
line-height: 33px;
text-align: left;
@media screen and (max-width:991px){
    font-size: 17px;
    line-height: 38px;
  
  }
  @media screen and (max-width:747px){
    font-size: 16px;
    line-height: 28px;
  
  }
@media screen and (max-width:474px){
    font-size: 14px;
    line-height: 28px;

}

}
.rblog-col
{
    display: flex;
    flex-direction: column;
}
.rblog{
    font-family: Poppins;
font-size: 20px;
font-weight: 600;
line-height: 20.92px;
color: black;
margin: 0;


}
.rblog-title{
    margin:0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 27.36px;
    text-align: left;
    color: black;
@media screen and (max-width:991px){
    font-size: 16px;
    line-height: 32px;
  
  
  }
  @media screen and (max-width:747px){
    font-size: 15px;
    line-height: 28px;
  
  
  }
@media screen and (max-width:474px){
    font-size: 14px;
    line-height: 22px;

}
}
.rblog-detail{
    font-family: Poppins;
font-size: 15px;
font-weight: 400;
line-height: 25px;
text-align: left;
@media screen and (max-width:991px){
    font-size: 15px;
    line-height: 32px;
  
  
  }
  @media screen and (max-width:747px){
    font-size: 14px;
    line-height: 28px;
  
  
  }
@media screen and (max-width:474px){
    font-size: 12px;
    line-height: 22px;

}
}

.mainNews{
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width:70%;
    margin:0 auto;
    @media screen and (max-width:991px){
      padding: 10px;
      width:100%;
  }
    @media screen and (max-width:474px){
        padding: 10px;
        width:100%;
    }
}
.mainNews1-firstDiv{
  
  max-width: 347px;
height: fit-content;
padding: 30px;
border-radius: 5px ;
padding-left: 50px;
box-shadow: 0px 0px 15px 2px #0000001A;
background: #F6F6F6;

font-family: Poppins;
font-size: 14px;
font-weight: 400;
line-height: 21px;
text-align: left;

@media screen and (max-width:991px){
display: none;

}

}
.rblogs-title{
  width: 290px;
height: fit-content;

border-radius: 5px ;
background: #F6F6F6;
box-shadow: 0px 0px 15px 2px #0000001A;
font-family: Poppins;
font-size: 18px;
font-weight: 400;
line-height: 27px;
text-align: left;
padding: 25px;
@media screen and (max-width:991px){
  display: none;
  
  }
}
.news-popular{
  width: 198px;
height: 35px;
font-family: Poppins;
font-size: 24px;
font-weight: 600;
line-height: 35.45px;
text-align: left;
color:black;
margin-top: 60px;
margin-bottom: 30px;
@media screen and (max-width:991px){
  display: none;
  
  }
}
.news-borderd{
  position: relative;
  /* margin-bottom: 20px; */
  &:before {

    /* background-color: white; */
    left:-40px;
    /* top:10px; */
    position: absolute; 
    content: url(../../assets/aboutLineGroupNews.svg);
  
    /* @media screen and (max-width:991px){
      left:-20px;
   
      top:10px;
    }
    @media screen and (max-width:767px){
      left:30px;
      top:10px;
    }
    @media screen and (max-width:474px){
      left:30px;
      top:5px;
    
    } */
  }
  }
  .news-gBorderd{
    position: relative;
    /* margin-bottom: 20px; */
    &:before {
  
      /* background-color: white; */
      left:-40px;
      /* top:10px; */
      position: absolute; 
      content: url(../../assets/newsGrayBefore.svg);
    
      /* @media screen and (max-width:991px){
        left:-20px;
     
        top:10px;
      }
      @media screen and (max-width:767px){
        left:30px;
        top:10px;
      }
      @media screen and (max-width:474px){
        left:30px;
        top:5px;
      
      } */
      
    }
  
  @media screen and (max-width:991px){
    margin-bottom: 0px;
    padding-left: 20px;
  }
  @media screen and (max-width:767px){
    margin-bottom: 25px;
    padding-left: 65px;
  }
  @media screen and (max-width:474px){
    margin-bottom: 10px;
  
  }

}
.mainNews1{
  padding: 43px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width:30%;
  @media screen and (max-width:991px){
    display: none;
    
    }
  }
.mainDetails{
  padding: 30px;
  display: flex;
  
  justify-content: center;
  width:100%;
  margin:0 auto;
}
.blog-title2{
    font-family: Poppins;
    font-size: 28px;
    font-weight: 500;
    line-height: 42px;
    text-align: left;
    padding: 13px;
  margin: 0;
    
color:black;
@media screen and (max-width:991px){
    font-size: 20px;
    line-height: 32px;
  
  
  }
  @media screen and (max-width:747px){
    font-size: 18px;
    line-height: 28px;
  
  
  }
@media screen and (max-width:474px){
    font-size: 16px;
    line-height: 22px;

}
}
.blog-detail2{
    font-family: Poppins;
font-size: 24px;
font-weight: 500;
line-height: 42px;
text-align: left;
padding:10px;

@media screen and (max-width:991px){
    font-size: 20px;
    line-height: 38px;
  
  }
  @media screen and (max-width:747px){
    font-size: 18px;
    line-height: 28px;
  
  }
@media screen and (max-width:474px){
    font-size: 16px;
    line-height: 28px;

}


}
.blog-img2{
    width:100%;

border-radius: 20px ;


}
.blog-img2 img{
    border-radius: inherit
    ;
    width: 100%;
    /* height: 100%; */
 
    background-size: cover;
 
   
}
.rblog-img{
    width:177px;
    height: 170px;
    ;
   
}
.rblog-img-img{
    width:177px;
    height: 170px;
   
    border-radius: 10px;
}
.linkMore{
    color:blue;
    text-decoration:underLine;
    font-size:15px;
    font-weight:400;
    padding-left:10px;
    cursor: pointer;
    @media screen and (max-width:474px){
      font-size:12px;
    /* padding-left:0px; */
    }
  }
  .rblog-div{
display:flex;
flex-direction:row;
gap:20px;
@media screen and (max-width:474px){
 flex-direction: column;
 gap:10px;
  }
}

.mainNews2{

    max-width: 1200px ;
    position: relative;
    margin: 0 auto;
    justify-content: center;
    /* align-items: center; */
    padding: 2rem;
    overflow-x: hidden;
    margin-bottom:10px;
    display: flex;
    @media screen and (max-width:991px){
      margin-top: -40px;
    
    
    }
    @media screen and (max-width:747px){
      margin-top: -50px;
    
    
    }
@media screen and (max-width:474px){
  margin-top:90px;
  padding: 0.5rem;


}
    
}
// .rankBgc{
//   position: absolute;
//   top:-50px;
//   right:100px;

//   width:35%;

// }
.mainc{
   
  position: relative;

   margin-bottom:10px;



   max-width: 1200px;

 margin: 0 auto;
 justify-content: center;
 align-items: center;
 padding: 2rem;
   @media screen and (max-width:991px){
     margin-top: -40px;
   
   
   }
   @media screen and (max-width:747px){
     margin-top: -50px;
     padding: 1rem;
   
   
   }
@media screen and (max-width:474px){
 margin-top: -55px;


}
   
 }
.leftForm{
  z-index: 1;
  //right: 50px;
  //position: absolute;
  display: none;
  align-items: center;
  padding: 40px;
  background-image: url(../../assets/peapper.svg);
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: -10px;
  margin-bottom: 30px;
  width: 42%;
  height:max-content;
  min-height: max-content;
  max-height: max-content;
  object-fit: fill;
 


  @media screen and (max-width: 474px) {
    
    width: 100%;
    display: block;
   // height: 132%;
  }
}
.rightForm {
  z-index: 1;
  right: 50px;
  position: absolute;
  align-items: center;
  padding: 42px;
  background-image: url(../../assets/peapper.svg);
  background-size: cover;
  background-repeat: no-repeat;
  top: -50px;
  width: 42%;
  height:max-content;
  min-height: max-content;
  max-height: max-content;
  object-fit: fill;
  @media screen and (max-width: 1200px) {
    right: 20px;

    //height: 150%;
    padding: 20px;
  }
  @media screen and (max-width: 991px) {
    right: 15px;
    width: 48%;
    //height: 160%;
    top: -25px;
    padding: 20px;
  }
  @media screen and (max-width: 767px) {
    right: 5px;
    width: 50%;
    //height: 175%;
    top: -5px;
  }

  @media screen and (max-width: 474px) {
    right: 0px;
    width: 100%;
   // height: 132%;
   display: none;
  }
}
.nextStep{
  width:30%;
}
.fcInput {
  background: transparent;
  width: 100%;
  border: 1px solid black;
  border-radius: 7px;
  height: 55px;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 11px;
  @media screen and (max-width: 1200px) {
    height: 40px;
  }
  @media screen and (max-width: 991px) {
    height: 30px;
  }
  @media screen and (max-width: 767px) {
    height: 30px;
  }
  @media screen and (max-width: 474px) {
    height: 30px;
    font-size: 14px;
    font-weight: 500;
  }
}
.fcInputArea {
  background: transparent;
  width: 100%;
  border: 1px solid black;
  border-radius: 7px;
  height: 100px;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
  resize: none;
  @media screen and (max-width: 1200px) {
    height: 220px;
  }
  @media screen and (max-width: 991px) {
    height: 200px;
  }
  @media screen and (max-width: 767px) {
    height: 150px;
  }
  @media screen and (max-width: 474px) {
    height: 150px;
    font-size: 14px;
    font-weight: 500;
  }
}
.pRrank {
  font-family: Poppins;
  font-size: 17px;
  color: black;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  @media screen and (max-width: 1200px) {
    font-size: 15px;
  }
  @media screen and (max-width: 991px) {
    font-size: 15px;
  }
  @media screen and (max-width: 767px) {
    font-size: 15px;
  }


  @media screen and (max-width: 474px) {
    font-size: 14px;
  }

}
.firstRow{
  margin-top:30px;
  
  @media screen and (max-width: 1200px) {
    font-size: 70px;
  }
  @media screen and (max-width: 991px) {
    font-size: 40px;
  }
  @media screen and (max-width: 767px) {
    font-size: 0px;
    width:40%;
    margin-top:50px;
 
  }
  @media screen and (max-width: 474px) {
  
    margin-left:0;
  }
}
.secondRow{
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top:100px;
  overflow: hidden;
  @media screen and (max-width: 1200px) {
    margin-top:100px;
  }
  @media screen and (max-width: 991px) {
    margin-top:50px;
  }
  @media screen and (max-width: 474px) {
    margin-top:50px;
  }
}
.pbRank {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  color: black;
  letter-spacing: 0em;
  text-align: center;
  @media screen and (max-width: 1200px) {
    font-size: 15px;
  }
  @media screen and (max-width: 991px) {
    font-size: 14px;
  }
  @media screen and (max-width: 767px) {
    font-size: 14px;
  }
  @media screen and (max-width: 474px) {
    font-size: 14px;
  }
}
.rankBtn {
  width: 100%;
  height: 55px;
  border-radius: 10px;
  margin-bottom: 10px;
  border-style: none;
  font-weight: 600;
  font-size: 21px;
  background: linear-gradient(90deg, #7ff593 0%, #68eaae 51.56%, #53dfc7 100%);
  @media screen and (max-width: 1200px) {
    height: 50px;
    font-size: 20px;
  }
  @media screen and (max-width: 991px) {
    height: 40px;
    font-size: 19px;
  }
  @media screen and (max-width: 767px) {
    height: 30px;
    font-size: 18px;
  }
  @media screen and (max-width: 474px) {
    height: 30px;
    font-size: 16px;
  }
}
.ptdiv {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: black;
  @media screen and (max-width: 767px) {
    font-size: 16px;
    display: inline-block;
    width: 400px;
  
  }

  @media screen and (max-width: 474px) {
    font-size: 15px;
    display: inline-block;
    width: 200px;
    line-height: 10px;

  }
}
.ptdiv1{
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: black;
  @media screen and (max-width: 767px) {
    font-size: 15px;
    display: inline-block;
    width: max-content;
  
  }

  @media screen and (max-width: 474px) {
    font-size: 15px;
    display: inline-block;
    width: max-content;

  }
}
.steps{
  margin-top:10px;
  margin-left:10px;
  @media screen and (max-width: 767px) {
    margin-top:20px;
  
  }
  @media screen and (max-width: 474px) {

  margin-top: -10px;
  margin-left:25px;
  


  }
}
.psdiv {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: black;
  @media screen and (max-width: 991px) {
    font-size: 16px;
    display: inline-block;
  }
  @media screen and (max-width: 767px) {
    font-size: 16px;
    display: inline-block;
    width: 250px;
    
  }

  @media screen and (max-width: 474px) {
    font-size: 15px;
    display: inline-block;

  }
}
.pStep {
  padding-left: 10px;
  font-family: Poppins;
  font-size: 22px;
  font-weight: 500;
  line-height: 70px;
  letter-spacing: 0em;
  text-align: left;
  color: black;
  @media screen and (max-width: 991px) {
    font-size: 14px;
  }
  @media screen and (max-width: 747px) {
    font-size: 14px;
    padding-left: 5px;
  }

  @media screen and (max-width: 474px) {
    font-size: 14px;
    line-height: 60px;
  }
}
.divStep {
  position: relative;
  &:before {
    left: -30px;
    top: 20px;
    position: absolute;
    content: url(../../assets//circleLine.svg);
    @media screen and (max-width: 474px) {
      content: url(../../assets//mStepLine1.svg);
     // top: 15px;
    }
  
  }
}
.divStep1 {
  position: relative;
  &:before {
    background-color: white;
    left: -30px;
    top: 20px;
    position: absolute;
    content: url(../../assets//gCircle.svg);
    @media screen and (max-width: 474px) {
      content: url(../../assets//mStepCircle.svg);
      top: 15px;
    }
  }
}
.emailDiv {
  margin-top: -20px;
  position: relative;
  left: 110px;
  line-height: 40px;
  color: black;
  font-size: 22px;
  @media screen and (max-width: 767px) {
    left:80px;
  }
  &:before {
    position: absolute;
    content: url(../../assets//emailIcon.svg);
    left: -35px;

    bottom: -8px;
    @media screen and (max-width: 474px) {
      content: url(../../assets//mMail.svg);
      left: -25px;
      bottom: -4px;
    }
  }
  @media screen and (max-width: 474px) {
    left: 65px;
    font-size: 16px;
    margin-top: -30px;
  }
}
.phoneDiv {
  position: relative;
  margin-top:10px;
  left: 110px;
  font-size: 22px;
  color: black;
  @media screen and (max-width: 767px) {
    left:80px;
  }
  &:before {
    position: absolute;
    content: url(../../assets//phoneIcon.svg);
    left: -35px;
    bottom: -8px;
    @media screen and (max-width: 474px) {
      content: url(../../assets//mPhone.svg);
      left: -25px;
      bottom: -4px;
    }
 
  }
  @media screen and (max-width: 474px) {
    left: 65px;
    font-size: 16px;
  }
}
.info {
  font-weight: bolder;
}
.talent {
  padding-top:80px;
  padding-left:0px;
  @media screen and (max-width: 991px) {
    padding-top:0;
    
  }
  @media screen and (max-width: 767px) {
   margin-left: 50px;
    
  }
  
  @media screen and (max-width: 474px) {
    padding-top:0;
    margin-left: 25px;
    
  }
}
.parentContact {
  position: relative;
 width: 80%;
  height:400px;
  padding: 20px;
  border-radius: 20px;
 
 
  transform: rotate(5deg) ;

  border-top: 4px solid #7ff593;
  border-bottom: 4px solid #68eaae;
  border-left: 4px solid #53dfc7;
  border-right: 4px solid #68eaae;
  background-image: linear-gradient(#7ff593, #68eaae),
    linear-gradient(#68eaae, #53dfc7);
  background-size: 1px 100%;

  background-repeat: no-repeat;
  @media screen and (max-width: 1200px) {
    width:80%;
  }
   @media screen and (max-width: 991px) {
    width:70%;
  }
  @media screen and (max-width: 767px) {
    width:45%;
  }
  @media screen and (max-width: 474px) {
    width:70%;
  }
}
.parentContactH {
  position: relative;
  width: 80%;
   height:400px;
   padding: 20px;
   border-radius: 20px;
   transform: rotate(0deg);
   border-top: 4px solid #7ff593;
   border-bottom: 4px solid #68eaae;
   border-left: 4px solid #53dfc7;
   border-right: 4px solid #68eaae;
   background-image: linear-gradient(#7ff593, #68eaae),
     linear-gradient(#68eaae, #53dfc7);
   background-size: 1px 100%;
 
   background-repeat: no-repeat;

}
.secondContact {
  display: block;
  position: absolute;
  transform: rotate(-5deg);
  padding-top: 60px;
  border-radius: 20px;
  text-align: center;
  width: 100%;
  height:100%;
  background-image: linear-gradient(#7ff593, #68eaae),
    linear-gradient(#68eaae, #53dfc7);

  background-repeat: no-repeat;
  top: 0;
  left: 0;
  @media screen and (max-width: 474px) {
    display: none;
  }
  
}
.secondContact1 {
  display: none;
  position: absolute;
  transform: rotate(-5deg);
  padding-top: 60px;
  border-radius: 20px;
  text-align: center;
  width: 100%;
  height:100%;
  background-image: linear-gradient(#7ff593, #68eaae),
    linear-gradient(#68eaae, #53dfc7);

  background-repeat: no-repeat;
  top: 0;
  left: 0;
  @media screen and (max-width: 474px) {
    display: block;
 
  }
  
}
.pparent {
  align-items: end;
}
.talent1 {
  margin-top:300px;
  @media screen and (max-width: 991px) {
    margin-top:-200px;
    margin-left: 400px;
  }

  @media screen and (max-width: 767px) {
    margin-top:-100px;
    margin-left: 350px;
  }
  @media screen and (max-width: 474px) {
    margin-top:50px;
    margin-left: 80px;
  }

  &:hover {
    .locationBtn {
      display: block;  
      animation-name: locationB !important;
      animation-duration: 4s;
      width: 50%;
      height: 50%;
      transition: all ease-out;
    }
    .parentContact{
      // transform: rotate(-5deg);
      rotate:-10deg;
      transition: rotate 1s;
    }
    .secondContact{
      // transform: rotate(5deg);
      rotate:10deg;
      transition: rotate 1s;
    }
    @media (hover: none){
      .locationBtn{display: none;}
      .parentContact{
        rotate: 0deg;
      }
      .secondContact{
        rotate: 0deg;
      }
    }
  }
}
.lebanCol{   padding-left: 20px;
  @media screen and (max-width: 474px) {

    margin-left: 40px;
    margin-top: 10px;
  }

  &:hover {
    .locationBtn {
      display: block;  
      cursor:pointer;
      animation-name: locationB !important;
      animation-duration: 4s;
      width: 50%;
      height: 50%;
      transition: all ease-out;
    }
    .parentContact{
      // transform: rotate(-5deg);
      rotate:-10deg;
      transition: rotate 1s;
    }
    .secondContact{
      // transform: rotate(5deg);
      rotate:10deg;
      transition: rotate 1s;
    }
    @media (hover: none){
      .locationBtn{display: none;}
      .parentContact{
        rotate: 0deg;
      }
      .secondContact{
        rotate: 0deg;
      }
    }
  }
}
.lbanP {
  font-family: Poppins;
  font-size: 30px;
  font-weight: 600;
  color: black;
  position: relative;
  &:before {
    position: absolute;
    top: 5px;
    left: 40px;
    content: url(../../assets/location.svg);
    @media screen and (max-width: 991px) {
      left: 30px;
    }
    @media screen and (max-width: 767px) {
      left: 45px;
    }
    @media screen and (max-width: 474px) {
      left: 45px;
    }
    @media screen and (max-width: 455px) {
      left: 25px;
    }
  }
}
.lbanAddP {
  font-family: Poppins;
  font-size: 22px;
  padding: 10px 30px;
  color: black;
  @media screen and (max-width: 767px) {
    font-size: 20px;
  }
  @media screen and (max-width: 474px) {
    font-size: 18px;
  }
}
.lbanPhoneP {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 500;
  padding: 0 30px;
  color: black;
margin-top: -10px;
@media screen and (max-width: 767px) {
  font-size: 18px;
}
@media screen and (max-width: 474px) {
  font-size: 20px;
}
}

.lbanS {
  font-family: Poppins;
  font-size: 30px;
  font-weight: 600;
  color: black;
  position: relative;
  &:before {
    position: absolute;
    top: 5px;
    left: 70px;
    content: url(../../assets/location.svg);
    @media screen and (max-width: 991px) {
      left: 50px;
    }
    @media screen and (max-width: 767px) {
      left: 65px;
    }
   
    @media screen and (max-width: 474px) {
      left: 70px;
    }
    @media screen and (max-width: 455px) {
      left: 50px;
    }
  }
}
.locationBtn {
  position: absolute;
  display: block;
  top: 30%;
  left: 25%;
  display: none;



}
@keyframes locationB {
  0% {
    width: 0%;
    height: 0%;
    top: 50%;
    left: 45%;
    display: block;
  }

  100% {
    top: 30%;
    left: 25%;
    width: 50%;
    height: 50%;
    display: block;
  }
}


  .approachRow{
    margin-top: 100px;
    @media screen and (max-width:767px){
      margin-top: 40px;
      margin-left: 40px;
      gap: 20px;
    }
    @media screen and (max-width:474px){
      margin-top: 40px;
      margin-left: 10%;
      gap:0px;
     
    }
  }
  .deRow{
    @media screen and (max-width:767px){
      margin-top: 40px;
      margin-left: 40px;
      gap: 20px;
    }
    @media screen and (max-width:474px){
      margin-top: 0px;
      margin-left: 10%;
      gap:0px;
     
    }
  }
  .deRowL{
    @media screen and (max-width:767px){
      margin-top: 40px;
      margin-left: 50px;
     
    }
    @media screen and (max-width:474px){
      margin-top: 0px;
      margin-left: 10%;
     
    }
  }
  .showbf div {
    backface-visibility: visible;
  }
  
  .hidebf div {
    backface-visibility: hidden;
    
  }
  

  .container1 {
    width: 400px;
    height: 300px;
    margin: 0px auto;
   opacity:1;
    border: none;
    perspective:800px;
    @media screen and (max-width:767px){
      opacity:0;
      height:0px;
      display: none;
    }
    @media screen and (max-width:474px){
      opacity:0;
      height: 0px;
      display: none;
    }
  }
  
  .cube {
    width: 100%;
    height: 100%;
    margin: 0px auto;
  
    
    transform-style: preserve-3d;
    transform: translateZ(-1.5em)  rotateY(45deg) rotateX(-20deg) rotateZ(-20deg) ;
    transition: 1s ease-in-out;
    @media screen and (max-width:1200px){
      transform: translateZ(-1em)  rotateY(45deg) rotateX(-20deg) rotateZ(-20deg) ;
    }
    @media screen and (max-width:991px){
      transform: translateZ(3em)  rotateY(55deg) rotateX(-20deg) rotateZ(-20deg) ;
    }
  }
  .cube1 {
    width: 100%;
    height: 100%;
    margin: 0px auto;
   
    
    transform-style: preserve-3d;
    transform: translateZ(-1.5em) rotateY(50deg) rotateX(-20deg) rotateZ(-20deg);
    transition: 1s ease-in-out;
    @media screen and (max-width:1200px){
      transform: translateZ(1.5em)  rotateY(-30deg) rotateX(-30deg) rotateZ(-0deg);
    
      
      
  
    }
    @media screen and (max-width:991px){
      transform: translateZ(5em)  rotateY(-30deg) rotateX(10deg) rotateZ(-10deg);
      
    }
  }
  .cube2 {
    width: 100%;
    height: 100%;
    margin: 0px auto;
    transform-style: preserve-3d;
    transform: translateZ(-1.5em) rotateY(45deg) rotateX(-30deg) rotateZ(-20deg);
    transition: 1s ease-in-out;
    @media screen and (max-width:1200px){
      //transform: translateZ(1.5em) rotateY(30deg) rotateX(-10deg) rotateZ(-40deg);
      transform: translateZ(-1.5em) rotateY(45deg) rotateX(-30deg) rotateZ(-20deg);
  
    }
    @media screen and (max-width:991px){
      transform: translateZ(2.5em) rotateY(45deg) rotateX(-30deg) rotateZ(-20deg);
      
    }
  
  }
  .cube3 {
    width: 100%;
    height: 100%;
    margin: 0px auto;
    transform-style: preserve-3d;
    transform:  translateZ(-1.5em) rotateX(-70deg) rotateY(45deg) rotateZ(30deg);
    transition: 1s ease-in-out;
    @media screen and (max-width:1200px){
      transform: translateZ(1.5em) rotateX(20deg) rotateY(-45deg) rotateZ(-20deg);
    
    }
    @media screen and (max-width:991px){
      transform: translateZ(7em) rotateX(20deg) rotateY(-45deg) rotateZ(-20deg);
      
    }
  }
  .cube4 {
    width: 100%;
    height: 100%;
    margin: 0px ;
   
    
    transform-style: preserve-3d;
   transform: translateZ(-1em) rotateX( 60deg) rotateY(0deg) rotateZ(45deg);
   transition: 1s ease-in-out;
   @media screen and (max-width:1200px){
    transform: translateZ(1.5em) rotateX( -120deg) rotateY(-30deg) rotateZ(20deg);
  
  }
  @media screen and (max-width:991px){
    transform: translateZ(3em)  rotateY(55deg) rotateX(-30deg) rotateZ(-20deg) ;
    
  }
  }
  .cube5 {
    width: 100%;
    height: 100%;
    margin-left:0px ;
   
    
    transform-style: preserve-3d;
    transform: translateZ(-1em) rotateX( -20deg) rotateY(-60deg) rotateZ(10deg);
    transition: 1s ease-in-out;
    @media screen and (max-width:1200px){
      transform: translateZ(1.5em) rotateX( -10deg) rotateY(50deg) rotateZ(-30deg);
    
    }
    @media screen and (max-width:991px){
      transform: translateZ(3em)  rotateY(60deg) rotateX(-20deg) rotateZ(-20deg) ;
      
    }
  }
  
  .face {
  
    position: absolute;
    width: 5em;
    height: 5em;
    border: none;
   // line-height: 30px;
    font-family: Inter;
    font-size: 60px;
    color: black;
    text-align: center;
    @media screen and (max-width:1200px){
      width: 4em;
      height: 4em;
      
  
    }
    @media screen and (max-width:991px){
      width: 3em;
      height: 3em;
      
    }
    
  
  
  }
  
  /* Define each face based on direction */
  .front {
    background: rgba(150, 150, 150, 1);
    
    transform: translateZ(2.5em);
    @media screen and (max-width:1200px){
      transform: translateZ(2em);
  
    }
    @media screen and (max-width:991px){
      transform: translateZ(1.5em);
    }
  
  }
  .frontVisible{
  background-image: linear-gradient(90deg, #7FF593 0%, #68EAAE 51.56%, #53DFC7 100%);
  transform: translateZ(2.5em);
  @media screen and (max-width:1200px){
    transform: translateZ(2em);
  
  }
  @media screen and (max-width:991px){
    transform: translateZ(1.5em);
  }
  
  }
  .back {
    background: rgba(92, 94, 97, 1);
    transform: rotateY(180deg) translateZ(2.5em);
    @media screen and (max-width:1200px){
      transform: rotateY(180deg) translateZ(2em);
  
    }
    @media screen and (max-width:991px){
      transform: rotateY(180deg) translateZ(1.5em);
    }
   
  }
  .backVisible {
    background-image: linear-gradient(90deg, #7FF593 0%, #68EAAE 51.56%, #53DFC7 100%);
    transform: rotateY(180deg) translateZ(2.5em);
    @media screen and (max-width:1200px){
      transform: rotateY(180deg) translateZ(2em);
  
    }
    @media screen and (max-width:991px){
      transform: rotateY(180deg) translateZ(1.5em);
    }
  
  }
  
  .right {
    background: rgba(185, 188, 197, 1);
    transform: rotateY(90deg) translateZ(2.5em);
    @media screen and (max-width:1200px){
      transform: rotateY(90deg) rotateY(180deg) translateZ(2em);
  
    }
    @media screen and (max-width:991px){
      transform: rotateY(90deg) rotateY(180deg) translateZ(1.5em);
    }
  
  }
  .rightVisible {
    background-image: linear-gradient(90deg, #7FF593 0%, #68EAAE 51.56%, #53DFC7 100%);
    transform: rotateY(90deg) translateZ(2.5em);
    @media screen and (max-width:1200px){
      transform: rotateY(90deg) rotateY(180deg) translateZ(2em);
  
    }
    @media screen and (max-width:991px){
      transform: rotateY(90deg) rotateY(180deg) translateZ(1.5em);
    }
  
  }
  
  .left {
    // background: rgba(92, 94, 97, 1);
    // transform: rotateY(-90deg) translateZ(2.5em);
    // @media screen and (max-width:1200px){
    //  transform: rotateY(-90deg) rotateY(180deg) translateZ(2em);
  
    // }
    // @media screen and (max-width:991px){
    //  transform: rotateY(-90deg) rotateY(180deg) translateZ(1.5em);
    // }
   
    background: rgba(185, 188, 197, 1);
    transform: rotateY(-90deg) translateZ(2.5em);
    @media screen and (max-width:1200px){
      transform: rotateY(-90deg) rotateY(180deg) translateZ(2em);
      height: 240px !important;
  
    }
    @media screen and (max-width:991px){
      transform: rotateY(-90deg) rotateY(180deg) translateZ(1.5em);
      height: 180px !important;
    }
  }
  
  .leftVisible {
    background-image: linear-gradient(90deg, #7FF593 0%, #68EAAE 51.56%, #53DFC7 100%);
    transform: rotateY(-90deg) translateZ(2.5em);
    @media screen and (max-width:1200px){
      transform: rotateY(-90deg) rotateY(180deg) translateZ(2em);
   
     }
     @media screen and (max-width:991px){
      transform: rotateY(-90deg) rotateY(180deg) translateZ(1.5em);
     }
  
  }
  
  .top {
    background: rgba(185, 188, 197, 1);
    transform: rotateX(90deg) translateZ(2.5em);
    @media screen and (max-width:1200px){
     transform: rotateX(90deg) rotateY(180deg) translateZ(2em);
   
     }
     @media screen and (max-width:991px){
     transform: rotateX(90deg) rotateY(180deg) translateZ(1.5em);
     }
  
  }
  .topVisible {
    background-image: linear-gradient(90deg, #7FF593 0%, #68EAAE 51.56%, #53DFC7 100%);
    transform: rotateX(90deg) translateZ(2.5em);
    @media screen and (max-width:1200px){
      transform: rotateX(90deg) rotateY(180deg) translateZ(2em);
    
      }
      @media screen and (max-width:991px){
      transform: rotateX(90deg) rotateY(180deg) translateZ(1.5em);
      }
  
  }
  
  
  .bottom {
    background: rgba(92, 94, 97, 1);
    transform: rotateX(-90deg) translateZ(2.5em);
    @media screen and (max-width:1200px){
      transform: rotateX(-90deg) rotateY(180deg) translateZ(2em);
    
      }
      @media screen and (max-width:991px){
      transform: rotateX(-90deg) rotateY(180deg) translateZ(1.5em);
      }
  
  }
  .bottomVisible {
    background-image: linear-gradient(90deg, #7FF593 0%, #68EAAE 51.56%, #53DFC7 100%);
    transform: rotateX(-90deg) translateZ(2.5em);
    @media screen and (max-width:1200px){
      transform: rotateX(-90deg) rotateY(180deg) translateZ(2em);
    
      }
      @media screen and (max-width:991px){
      transform: rotateX(-90deg) rotateY(180deg) translateZ(1.5em);
      }
   
  }
  
  
  .content{
    font-family:Inter;
    font-size:30px;
    font-weight:300;
    text-align:center;
    line-height:230px;
  }
  .divP{
    font-size:20px;
    font-weight:600;
    margin-bottom: 7px;
    @media screen and (max-width:1200px){
        
      font-size:19px;
  
    }
    @media screen and (max-width:991px){
      font-size:18px;
      margin-left:0px;
      margin-top: -30px;
    }
    @media screen and (max-width:767px){
      font-size:17px;
    }
    @media screen and (max-width:464px){
      font-size:16px;
      margin:auto;
    }
    &:hover{
      .unDefindepartment{
        margin-top: 20px;
          
           @media screen and (max-width:767px){
           margin-left: 40px;
           }
         }
    }
  }
  

  .unDefindepartment:hover{
    scale:1.1;
    color:black;
    cursor:pointer;
    padding-left:0px;
    @media screen and (max-width:474px){
    padding-left:10px;
   
    }
  }
  
  .pDetails{
    font-size:15px;
  width:250px;
    text-transform:capitalize;
    @media screen and (max-width:1200px){
        
      font-size:14px;
  
    }
    @media screen and (max-width:991px){
      font-size:13px;
    }
    @media screen and (max-width:767px){
      font-size:12px;
    }
    @media screen and (max-width:474px){
      font-size:14px;
      margin-bottom: 30px;
  
    }
  }
  .lastCol
  {
    padding-left:400px;
 
    @media screen and (max-width:1200px){
        
      padding-left:350px;
     
  
    }
    @media screen and (max-width:991px){
     padding-left:350px;
    }
    @media screen and (max-width:767px){
      padding-left: 0;
    }
    @media screen and (max-width:474px){
     padding-left: 10px;
    }
  
  }
  .colPadding{
   margin-left: 0px;
   padding-left:100px;
    @media screen and (max-width:1200px){
        
      padding-left:0px;
      margin-left: 0px;
  
    }
    @media screen and (max-width:991px){
      margin-left: 0px;
      padding-left:0px;
    }
    @media screen and (max-width:767px){
      margin-left: 10px;
      padding-left:0px;
    }
    @media screen and (max-width:474px){
      margin-left: 10px;
      padding-left:0px;
    }
   
  }
 .col13Padding{
 
 }
 .unDefindepartment{

  @media screen and (max-width:474px){
   margin-bottom: 50px;
    }
 }